import React, { useState, useContext, useEffect}  from 'react';
import { ThemeContext, ConstantsContext } from "../index";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import NativeSelect from '@mui/material/NativeSelect';
import { NumericFormat } from 'react-number-format';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import IconButton from '@mui/material/IconButton';
import Collapse from '@mui/material/Collapse';
import CloseIcon from '@mui/icons-material/Close';
import Alert from '@mui/material/Alert';
import {roundNumberIfNeeded} from './functions';


const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));
  
  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }));
  
  function createData(data_type, data_id, name, month, pnl) {
    return { data_type, data_id, name, month, pnl };
  }

export default function PnlListUsersMultiSelect(props) {

    const [staticText] = useContext(ConstantsContext);

  const [theme] = useContext(ThemeContext);
  const [pnlList, setPnlList] = useState([])
  const [filterMonthsArr, setFilterMonthsArr] = useState([])
  const [filterMonth, setFilterMonth] = useState(props.month)

  const [checkedUserIds, setCheckedUserIds] = useState([])
  const [namesOfUsers, setNamesOfUsers] = useState('')
  const [usersNamesJson, setUsersNamesJson] = useState({})

  const [warningMessage, setWarningMessage] = useState('')
  
  //let checkedUserIds = [];
  
  const [checked, setChecked] = React.useState(false);
  const [isChanged, setIsChanged] = React.useState(false);
  

  let onlyOnce = true;

  const handleChange = (e) => {
    //console.log(e.target.value)
    //console.log(e.target.checked)
    //console.log('_user_id_checkbox = ', e.target.value)
    let _checkedUserIds = checkedUserIds;
    
    if(e.target.checked) {
      if(_checkedUserIds.length < 3) {
        _checkedUserIds.push(parseInt(e.target.value))
        //checkedUserIds.push(parseInt(e.target.value))
        setWarningMessage('')
      } else {

        setWarningMessage('You can select max 3 traders')
      }
      
    } else {
      if(_checkedUserIds.length > 1) {

        const index = _checkedUserIds.indexOf(parseInt(e.target.value));
        if (index !== -1) {
          _checkedUserIds.splice(index, 1);
        }
        setWarningMessage('')
      } else {

        setWarningMessage('1 trader at least must be selected')
      }
      
    }

    //////////////////////////////////////////////
    let _namesOfUsers = '';
    _checkedUserIds.map((val, key) => {
        if(key != 0) {
          _namesOfUsers = _namesOfUsers + ', '
        }
        _namesOfUsers = _namesOfUsers + usersNamesJson[parseInt(val)]
        
        
    })
    setNamesOfUsers(_namesOfUsers)
    //////////////////////////////////////////////
    
    setCheckedUserIds(_checkedUserIds)
    props.setUser_idsFnc(_checkedUserIds.join(','))
    setIsChanged(!isChanged)
    //console.log('_checkedUserIds = ', _checkedUserIds);
    //console.log('checkedUserIds = ', checkedUserIds);
  };

////////////////////////////////////////forgotpassword_activationcode_request 
function _api_get_pnl_user_public_callback(_data) {

    //console.log('_api_get_pnl_user_public_callback _data = ', _data)
    if(_data.status_code != 2) {
      
      
    } else {
      
      
      /*setFilterMonthsArr(_data.monthsArray)
      if(filterMonth == '') {
        setFilterMonth(_data.monthsArray[0])
      }
      */
      setPnlList(_data.data)

      let _usersNamesJson = usersNamesJson;
      _data.data.map((val) => {
        //if(val == props.user_ids) {
          _usersNamesJson[val.id] = val.name;
        //}
      })
      //console.log('_usersNamesJson = ', _usersNamesJson)
      setUsersNamesJson(_usersNamesJson)
      //setCheckedUserIds = [props.user_ids]
      let _checkedUserIds = [];
      let _namesOfUsers = '';
      _data.user_idsArr.map((val, key) => {
        //if(val == props.user_ids) {
          _checkedUserIds.push(parseInt(val))
          
          if(key != 0) {
            _namesOfUsers = _namesOfUsers + ', '
          }
          _namesOfUsers = _namesOfUsers + _usersNamesJson[parseInt(val)]
        //}
      })
      setNamesOfUsers(_namesOfUsers)

      //console.log('_checkedUserIds = ', _checkedUserIds)
      setCheckedUserIds(_checkedUserIds)
      
    }
    
  }

  const _api_get_pnl_user_public = (_month, _user_ids) => {

    //console.log('_api_get_pnl_user_public _month = ', _month, 'props.userDetails.loggined_token = ', props.userDetails.loggined_token)
    let data = [
      {'key':'loggined_token', 'value': props.userDetails.loggined_token},
      {'key':'action', 'value': 'get_pnl_user'},
      {'key':'filterByMonthValue', 'value': _month},
      {'key':'user_ids', 'value': _user_ids}
    ]

    props._apiConnect(staticText.path_darts, data, _api_get_pnl_user_public_callback);
  }

  function formatDate(inputDate) {
    const [year, month] = inputDate.split('-');
    const date = new Date(year, month - 1);
    const monthName = date.toLocaleString('default', { month: 'short' });
    const formattedDate = `${monthName} ${year}`;
  
    return formattedDate;
  }

  useEffect(() => {
    
    //console.log('useEffect doings...')
    //localStorage.setItem('myValueInLocalStorage', event.target.value);

    //localStorage.getItem('loggined_token') == null || localStorage.getItem('loggined_token') == '' ? setLoggined_token('') : setLoggined_token(localStorage.getItem('loggined_token'));
    
    //console.log('loggined_token = ' + localStorage.getItem('loggined_token'));
    //getIPData();
    
    //console.log('PnlListUsersMultiSelect')

    //if(onlyOnce) {
      //onlyOnce = false;
      _api_get_pnl_user_public(props.month, props.user_ids);
    //}
    
    
  }, [props.month, props.user_ids]);

  useEffect(() => {
 
    //console.log('checkedUserIds')
    

  }, [isChanged]);


  let rows = [];

  

  pnlList.map((val, key) => {
      rows.push(
        createData(val.type, val.id, val.name, val.month, val.pnl),
      ) 
  })

  return (
    <div>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography>Choosea a Trader ({namesOfUsers})</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            {props.month}
          </Typography>
          {warningMessage == '' ? 
            null 
          : 
            <Collapse in={warningMessage != ''}>
              <Alert
                severity="error"
                action={
                  <IconButton
                    aria-label="close"
                    color="inherit"
                    size="small"
                    onClick={() => {
                      setWarningMessage('');
                    }}
                  >
                    <CloseIcon fontSize="inherit" />
                  </IconButton>
                }
                sx={{ mb: 2 }}
              >
                {warningMessage}
              </Alert>
            </Collapse>
          }
          {pnlList.length > 0 ? 
            <div>
                <TableContainer component={Paper} sx={{ maxHeight: 400 }}>
                <Table sx={{ maxWidth: 400 }} aria-label="customized table">
                    <TableHead>
                    <TableRow>
                        <StyledTableCell>Check</StyledTableCell>
                        <StyledTableCell>Analyst / Benchmark</StyledTableCell>
                        <StyledTableCell align="right">Month</StyledTableCell>
                        <StyledTableCell align="right">MTD&nbsp;P&L %</StyledTableCell>
                    </TableRow>
                    </TableHead>
                    <TableBody>
                    {rows.map((row, key) => {
                      let valBool= checkedUserIds.indexOf(parseInt(row.data_id)) > -1 ? true : false
                        return(
                          <StyledTableRow key={`pnllist_${key}`} style={{cursor:'pointer'}}>
                              <StyledTableCell component="th" scope="row">
                                  <Checkbox
                                      key={`checking_${row.data_id}`}
                                      value={row.data_id}
                                      checked={valBool}
                                      onChange={handleChange}
                                      inputProps={{ 'aria-label': 'controlled' }}
                                      style={{color:'#23A9F2'}}
                                  />
                              </StyledTableCell>                            
                              <StyledTableCell component="th" scope="row">
                                  {row.name}
                              </StyledTableCell>
                              <StyledTableCell align="right">{row.month}</StyledTableCell>
                              <StyledTableCell align="right">{roundNumberIfNeeded(row.pnl, 2)}%</StyledTableCell>
                          </StyledTableRow>
                        )
                    })}
                    </TableBody>
                </Table>
                </TableContainer>
            </div>
            :
            null
            }
          
        </AccordionDetails>
      </Accordion>
    </div>
  );
}

import React, { useContext } from 'react';
import { ThemeContext } from "../index";
import {numberWithCommas, roundDecimals, numberToAmericanFormat} from './functions';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import { useHistory } from "react-router-dom";
import Button from '@mui/material/Button';


const Item = styled(Typography)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'left',
  fontSize: 13,
}));

export default function DartLowHighAverage(props) {

  const [theme] = useContext(ThemeContext);

  const history = useHistory();

  const changeHistory = (_from, _to) => {
    history.push(_to, { from: _from })
  };

  return (

    <Grid container spacing={2} columns={12} rowSpacing={1} direction="row" justifyContent="center" alignItems="center" style={{width:'100%', maxWidth:180, fontSize:16}} >
        <Grid item xs={12} sm={12} md={12} lg={12} align="center" justify="center">
            <div style={{alignItems: 'center', backgroundColor:theme.infoPanelBackground, padding:3, borderRadius:5, width:'100%'}}>
            Target High Estimate (USD)
            </div>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} align="center" justify="center">
            <TextField
            sx={theme.inputTextSx}
            id="outlined-textarea"
            placeholder="e.g. your email"
            size="small"
            style={{width:'100%', align:'center'}}

            inputProps={{style: {fontSize: 13, padding: '8px 14px', textAlign:'center', color:theme.foreground}, readOnly: true}}
            value={numberToAmericanFormat(props.high, 4)}
            />
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} align="center" justify="center">
            <div style={{alignItems: 'center', backgroundColor:theme.infoPanelBackground, padding:3, borderRadius:5, width:'100%'}}>
            Target Low Estimate (USD)
            </div>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} align="center" justify="center">
            <TextField
            sx={theme.inputTextSx}
            id="outlined-textarea"
            placeholder="e.g. your email"
            size="small"
            style={{width:'100%'}}
            inputProps={{style: {fontSize: 13, padding: '8px 14px', textAlign:'center', color:theme.foreground}, readOnly: true}}
            value={numberToAmericanFormat(props.low, 4)}
            />
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} align="center" justify="center">
          <div style={{alignItems: 'center', backgroundColor:theme.infoPanelBackground, padding:3, borderRadius:5, width:'100%'}}>
            Range
          </div>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} align="center" justify="center">
          <div style={{alignItems: 'center', backgroundColor:theme.infoPanelBackground, padding:3, borderRadius:5, width:'100%'}}>
            {numberToAmericanFormat((100 * (props.high - props.low) / props.low), 2)}%
          </div>
        </Grid>
    </Grid>
  );
}


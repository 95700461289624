import React, { useContext, useState, useEffect } from 'react';
import { ThemeContext } from "../index";
import Grid from '@mui/material/Grid';
import { makeStyles } from '@material-ui/core/styles';
import SignIn from "./SignIn";
import signinlogo from '../assets/img/signinlogo.png';
import home_cryptos from '../assets/img/home_cryptos.png';
import ParticlesBg from 'particles-bg';
import "./youtubeStyles.css";
import YoutubeEmbed from "./YoutubeEmbed";
import ButtonCustomStyle from "./ButtonCustomStyle";
import EthereumGold from '../assets/img/ethereum-gold.png';
import PnlListView from "./PnlListView";




const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    backgroundColor: '#FFFFFF',
    padding:20,
  },
  root_iframe: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(0),
    },
    padding: theme.spacing(0,0),
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: '#1976D2',
  },
  form: {
    width: '100%'
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default function RouteSignIn(props) {

  const [theme] = useContext(ThemeContext);
  console.log("RouteSignIn theme", theme);


  const classes = useStyles();

  const IframeComponent = () => {

    return <iframe width="100%" height={930} src="https://embed.dappportfol.io/market/v1/usd/1/10/currency_off/pager_off/market_cap_rank,current_price,price_change_percentage_1h_in_currency,price_change_percentage_24h_in_currency,price_change_percentage_7d_in_currency" title="Market Widget" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
  };

  let config = {
    color: ["random", "#ff0000"],
  };

  const EthereumGoldIcon = () => {
    return(
      <img src={EthereumGold} style={{width:20, height:20}} />
    )
  }

  

  return (
    <Grid container spacing={2} columns={12} align="center" justify="center" style={{height: '100%', width:'100%'}} direction="row" justifyContent="center" alignItems="flex-start" >
      <ParticlesBg style={{zIndex:-1}} num={100} 
        type="cobweb" 
        config={config} 
        color='#8FD0F8'
        bg={{
          position: "absolute",
          zIndex: 1,
          height: 850,
          top: 0,
          left: 0,
          color:'#FFFFFF',
        }}
      />
      {/*<Grid style={{zIndex:2}} item xs={12} sm={12} md={12} lg={6} align="center" justify="center" order={{ xs: 2, sm: 2, md: 2, lg: 1 }}>
        <div className={classes.root_iframe} style={{paddingLeft:10}}>
            <IframeComponent />
        </div>
      </Grid>*/}
      <Grid style={{zIndex:10}} item xs={12} sm={12} md={12} lg={12} align="center" justify="center" order={{ xs: 1, sm: 1, md: 1, lg: 2 }}>
        <Grid container spacing={2} columns={12} align="center" justify="center" style={{height: '100%', fontSize:16}} direction="row" justifyContent="center" alignItems="center" >
          <Grid item xs={12} sm={12} md={12} lg={4} align="center" justify="center">
            <Grid container spacing={2} columns={12} align="center" justify="center" style={{height: '100%', width:'100%', maxWidth:400}} direction="row" justifyContent="center" alignItems="center" >
              <Grid style={{zIndex:1}} item xs={12} sm={12} md={12} lg={12} align="center" justify="center">
                <div>
                  <PnlListView noSignedIn={true} userDetails={props.userDetails} _apiConnect={props._apiConnect} />
                </div>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={8} align="center" justify="center">
            <Grid container spacing={2} columns={12} align="center" justify="center" style={{height: '100%', fontSize:16}} direction="row" justifyContent="center" alignItems="center" >
              {/*<Grid item xs={12} sm={12} md={12} lg={12} align="flex-end" justify="flex-end" justifyContent="flex-end">
                <div style={{fontSize:20, paddingTop:30, textAlign:'right'}}>
                  <ButtonCustomStyle
                    changeHistory={true}
                    doFnc={null}
                    changeHistoryFrom='/'
                    changeHistoryTo='/EarnMoney'
                    btnName='Earn Money'
                    endIcon={<EthereumGoldIcon />}
                  />
                </div>
              </Grid>*/}
              <Grid item xs={12} sm={12} md={12} lg={12} align="center" justify="center">
                <div style={{fontSize:20, paddingTop:30}}>
                  Welcome to CryptoMarketDarts
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} align="center" justify="center">
                <div style={{fontSize:16}}>
                  Time-sensitive Critical Research and Analysis for Top Crypto Currencies
                </div>
              </Grid>      
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <SignIn _apiSignIn={props._apiSignIn} signInErrorMessage={props.signInErrorMessage} userEmail={props.userEmail} />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <Grid container spacing={2} columns={12} align="center" justify="center" style={{height: '100%', width:'100%', maxWidth:600}} direction="row" justifyContent="center" alignItems="center" >
                  <Grid style={{zIndex:1}} item xs={12} sm={12} md={12} lg={12} align="center" justify="center">
                    <div style={{textAlign:'center'}} className="videoResponsive">
                      <YoutubeEmbed embedId="5SdzR5qUwkM" />
                    </div>
                  </Grid>
                </Grid>
              </Grid>
              
            </Grid>
          </Grid>
        </Grid>
      </Grid>      
    </Grid>
  );
}
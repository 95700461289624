import React, { useState, useEffect, useContext } from 'react';
import { ConstantsContext } from "../index";
import SignUp from "./SignUp";
import DartsDesign from "./DartsDesign";
import ConsumerHistoryGrid2 from "./ConsumerHistoryGrid2";
import {numberWithCommas, roundDecimals, numberToAmericanFormat} from './functions';
import LinearProgress from '@mui/material/LinearProgress';

import ShowChartIcon from '@mui/icons-material/ShowChart';
import OndemandVideoIcon from '@mui/icons-material/OndemandVideo';


import TextField from '@mui/material/TextField';


import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Button from '@mui/material/Button';


import '../App.css';
import { createStyles, makeStyles, useTheme, Theme } from '@material-ui/core/styles';
import FormReferAFriend from "./FormReferAFriend";
import SelectLabels from "./SelectLabels";
import DashboardTopMenu from "./DashboardTopMenu";
import GridView from "./GridView";


import Box from '@mui/material/Box';
import Popper from '@mui/material/Popper';
import { useHistory } from "react-router-dom";

import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import ThumbDownAltIcon from '@mui/icons-material/ThumbDownAlt';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
    },
    copyright: {
      padding: theme.spacing(2,2),
      alignItems: 'center',
      flex: 1,
      justifyContent: 'center'
    },
    nested: {
      paddingLeft: theme.spacing(1),
    },

    outerDiv: {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
      width: '100%',
      justifyContent: 'space-between'

    },
    leftDiv: {
      display: 'flex',
      flexDirection: 'column',
      flexBasis: '100%',
      flex: 1
    },
    rightDiv: {
      display: 'flex',
      flexDirection: 'column',
      flexBasis: '100%',
      flex: 1
    },
  }),
);

let currentCommentToEdit = '';
let currentCommentId = -1;

export default function RouteAdminGridView(props) {

  const [staticText] = useContext(ConstantsContext);

  const classes = useStyles();
  const theme = useTheme();

  const [rows, setRows] = useState([]);
  const colorsArr = {"-100": "#FFFFFF", "2": "#00B050", "1": "#25FF88", "0": "#808080", "-1": "#FFA6A0", "-2": "#FF0000"};
  const colorsNameArr = {"-100": "#FFFFFF", "2": "Bullish - Strong", "1": "Bullish", "0": "Neutral", "-1": "Bearish", "-2": "Bearish - Strong"};

  //const [currentCommentToEdit, setCurrentCommentToEdit] = useState('');

  const [loading, setLoading] = useState(false);

  const [loadStartPager, setLoadStartPager] = useState(0);

  

  const setCurrentCommentToEditFNC = (val, id) => {

    console.log('setCurrentCommentToEditFNC = ' + val)
    console.log('id = ' + id);
    currentCommentToEdit = val;
    currentCommentId = id;
  }


  function saveCurrentComment (id, active) {


    if(currentCommentToEdit != '' && currentCommentId == id) {

      console.log('save comment = ' + currentCommentToEdit + ', id=' + id + ', active = ' + active);
      _api_analyse_comment_edit_admin(id, active, currentCommentToEdit)
    } else {

      console.log('comment is empty');
    }
  }

  useEffect(() => {

    if(!props.userDetails.user_loged_in) {
  
      changeHistory('/AdminGridView', '/')
    } else {
      _api_get_analyse_all_admin();
      //changeHistory('/ReferAFriend', '/SignUp')
    }
  }, []);

  ////////////////////////////////////////get_analyse_all_admin
  function _api_analyse_comment_edit_admin_callback(_data) {

    if(_data.status_code != 2) {
      
      console.log('_api_analyse_comment_edit_admin error = ' + _data.msg);
      console.log('_api_analyse_comment_edit_admin code = ' + _data.status_code);
      
    } else {
      
      
      console.log('_api_analyse_comment_edit_admin was successfully sent');

      
      ///////////////////////////////////////////////////////////////////////
    }
    
  }

  const _api_analyse_comment_edit_admin = (id, active, comment) => {

    //setLoading(true)
    let data = [
      {'key':'action', 'value': 'analyse_comment_edit_admin'},
      {'key':'id', 'value': id},
      {'key':'active', 'value': active},
      {'key':'comment', 'value': comment},
      {'key':'loggined_token', 'value': props.userDetails.loggined_token}
    ]

    props._apiConnect(staticText.path_darts, data, _api_analyse_comment_edit_admin_callback)
  }
  //////////////////////////////////////

  ////////////////////////////////////////get_analyse_all_admin
  function _api_get_analyse_all_admin_callback(_data) {

    setLoading(false)
    if(_data.status_code != 2) {
      
      console.log('_api_get_analyse_all_admin error = ' + _data.msg);
      console.log('_api_get_analyse_all_admin code = ' + _data.status_code);
      
    } else {
      
      
      console.log('_api_get_analyse_all_admin was successfully sent');

      /*const rows = [
          {
            id: 1,
            dateCreated: '10/30/21',
            _1h: 'Bearish - Strong',
            _4h: 'Neutral',
            _1d: 'Bearish',
            _1w: 'Neutral',
            _1m: 'Bearish - Strong', 
            comments: 'the next possible move is a downside move towards',
            highEstimate: 62000, 
            lowEstimate: 62000,  
            chartLink: 'https://www.tradingview.com/x/dsvssfbfbs/',
            videoLink: 'https://www.youtube.com/watch?v=HbLQxJ-QgCI',
            likes: 45,
            dislikes: 1,
          },*/

          let _rows = [];
          //_data.data.sort((a, b) => a.date > b.date ? -1 : 1);
          _data.data.map((value, key) => {

              _rows.push({

                  id: value.id,
                  contributor: value.username,
                  coin: value.coin_pair_name,
                  dateCreated: <div style={{fontSize:13}}>{value.date}</div>,
                  _1h: <div style={{backgroundColor:colorsArr[value._1h], height:50, display: 'flex', alignItems: 'center', justifyContent: 'center'}}>{colorsNameArr[value._1h]}</div>,
                  _4h: <div style={{backgroundColor:colorsArr[value._4h], height:50, display: 'flex', alignItems: 'center', justifyContent: 'center'}}>{colorsNameArr[value._4h]}</div>,
                  _1d: <div style={{backgroundColor:colorsArr[value._1d], height:50, display: 'flex', alignItems: 'center', justifyContent: 'center'}}>{colorsNameArr[value._1d]}</div>,
                  _1w: <div style={{backgroundColor:colorsArr[value._1w], height:50, display: 'flex', alignItems: 'center', justifyContent: 'center'}}>{colorsNameArr[value._1w]}</div>,
                  _1m: <div style={{backgroundColor:colorsArr[value._1m], height:50, display: 'flex', alignItems: 'center', justifyContent: 'center'}}>{colorsNameArr[value._1m]}</div>, 
                  comments: <div style={{paddingLeft:10, textAlign:'left'}}><TextField
                              id={"outlined-textarea" + value.id}
                              multiline
                              placeholder="can not be empty"
                              size="small"
                              style={{width:220}}
                              inputProps={{style: {fontSize: 13, padding: '4px 7px', textAlign:'left'}}}
                              defaultValue={value.comment}
                              onChange={(event) => setCurrentCommentToEditFNC(event.target.value, value.id)}
                              />
                              <div style={{fontSize:12, paddingTop:10, textAlign:'right', cursor:'pointer'}}
                                onClick={(event) => saveCurrentComment(value.id, value.active)}
                              >Save</div>
                            </div>,
                  highEstimate: numberToAmericanFormat(value.high, 5), 
                  lowEstimate: numberToAmericanFormat(value.low, 5),  
                  chartLink: value.chart_link != '' ? (<a href={value.chart_link} target="_blank"><ShowChartIcon /></a>) : <ShowChartIcon style={{color:'#cccccc'}} />,
                  videoLink: value.video_link != '' ? (<a href={value.video_link} target="_blank"><OndemandVideoIcon /></a>) : <OndemandVideoIcon style={{color:'#cccccc'}} />,
                  likes: value.like,
                  dislikes: value.dislike,
                  status: value.active == 1 ? <div style={{color:'#00cc00'}}>Active</div> : <div style={{color:'#cc0000'}}>Expired</div>,
                  dateCreatedFormat:value.date
              });
          })

      //setGetAnalyseAll(_data.data);
      let concated_arr = rows.concat(_rows);
      concated_arr.sort((a, b) => a.dateCreatedFormat > b.dateCreatedFormat ? -1 : 1);
      setRows(concated_arr);
      //setGetAnalyseAllCount(_data.count);
      //_data.data.sort((a, b) => a.left_side > b.left_side ? 1 : -1);

      //setMyPortfolioListArr(_data.data)
      ///////////////////////////////////////////////////////////////////////
    }
    
  }

  const _api_get_analyse_all_admin = () => {

    
    
    let data = [
      {'key':'action', 'value': 'get_analyse_all_admin'},
      {'key':'start', 'value': loadStartPager * 10},
      {'key':'limit', 'value': 10},
      {'key':'loggined_token', 'value': props.userDetails.loggined_token}
    ]


    let p = loadStartPager + 1;
    setLoadStartPager(p);

    props._apiConnect(staticText.path_darts, data, _api_get_analyse_all_admin_callback)

    setLoading(true)
  }
  //////////////////////////////////////
  
  const history = useHistory();

  const changeHistory = (_from, _to) => {
    history.push(_to, { from: _from })
  };

  const columns = [
    { field: 'contributor', headerName: 'Contr.', width: 140, editable: false },
    { field: 'coin', headerName: 'Coin', width: 110, editable: false },
    {
      field: 'dateCreated',
      headerName: 'Date',
      type: 'date',
      width: 150,
      editable: false,
    },
    {
      field: '_1h',
      headerName: '1H',
      width: 130,
      editable: false,
    },
    {
      field: '_4h',
      headerName: '4H',
      width: 130,
      editable: false,
    },
    {
      field: '_1d',
      headerName: '1D',
      width: 130,
      editable: false,
    },
    {
      field: '_1w',
      headerName: '1W',
      width: 130,
      editable: false,
    },
    {
      field: '_1m',
      headerName: '1M',
      width: 130,
      editable: false,
    },
    
    
    {
      field: 'comments',
      headerName: 'Comments',
      width: 220,
      editable: true,
      renderCell: (params) => (
        <div style={{ flexShrink: 1 }} >
          {params.value}
        </div>
      ),
      
      height:200,
    },
    {
      field: 'highEstimate',
      headerName: 'High Est. (USD)',
      width: 90,
      editable: false,
    },
    {
      field: 'lowEstimate',
      headerName: 'Low Est. (USD)',
      width: 90,
      editable: false,
    },
    {
      field: 'chartLink',
      headerName: 'Chart',
      width: 70,
      editable: false,
    },
    {
      field: 'videoLink',
      headerName: 'Video',
      width: 70,
      editable: false,
    },
    {
      field: 'likes',
      headerName: <ThumbUpIcon />,
      width: 60,
      editable: false,
    },
    {
      field: 'dislikes',
      headerName: <ThumbDownAltIcon />,
      type: 'number',
      width: 60,
      editable: false,
    },
    {
      field: 'status',
      headerName: 'Status',
      width: 70,
      editable: false,
    },
  ];
  
  /*const rows = [
    {
      id: 1,
      contributor: 'Kripto-King',
      coin: 'BTC-USD',
      dateCreated: '10/30/21 11:41 PM',
      _1h: 'Bearish - Strong',
      _4h: 'Neutral',
      _1d: 'Bearish',
      _1w: 'Neutral',
      _1m: 'Bearish - Strong',
      lowEstimate: 62000,
      highEstimate: 62000,    
      comments: 'the next possible move is a downside move towards',
      chartLink: 'https://www.tradingview.com/x/dsvssfbfbs/',
      videoLink: 'https://www.youtube.com/watch?v=HbLQxJ-QgCI',
      likes: 45,
      dislikes: 1,
      status: 'Active',
      datCreatedFormat: 'Thursday, November 4, 2021 17:43',
    },
    {
      id: 2,
      contributor: 'Crypto D112',
      coin: 'LTC-USD',
      dateCreated: '10/30/21 11:41 PM',
      _1h: 'Bearish',
      _4h: 'Bearish - Strong',
      _1d: 'Bearish',
      _1w: 'Bullish - Strong',
      _1m: 'Bearish',
      lowEstimate: 62000,
      highEstimate: 62000, 
      comments: 'downside, we can close our buy trades if have any, now',
      chartLink: 'https://www.tradingview.com/x/kjhhgsdgdgfd/',
      videoLink: 'https://youtu.be/hXq-imi271w',
      likes: 85,
      dislikes: 3,
      status: 'Active',
      datCreatedFormat: 'Thursday, November 3, 2021 17:43',
    },
    {
      id: 3,
      contributor: 'God of Crypto',
      coin: 'ETH-USD',
      dateCreated: '10/30/21 11:41 PM',
      _1h: 'Bearish',
      _4h: 'Bullish',
      _1d: 'Bearish - Strong',
      _1w: 'Bullish - Strong',
      _1m: 'Bearish',
      lowEstimate: 62000,
      highEstimate: 62000, 
      comments: 'according to technical analysis is a move to the',
      chartLink: 'https://www.tradingview.com/x/hkhggdsdf/',
      videoLink: 'https://www.youtube.com/watch?v=uiTrB-zA7bc',
      likes: 13,
      dislikes: 7,
      status: 'Active',
      datCreatedFormat: 'Thursday, November 2, 2021 17:43',
    },
    {
      id: 4,
      contributor: 'WWW Crypto',
      coin: 'SHIVA-USD',
      dateCreated: '10/30/21 11:41 PM',
      _1h: 'Bearish',
      _4h: 'Bullish - Strong',
      _1d: 'Bearish',
      _1w: 'Bearish - Strong',
      _1m: 'Bearish',
      lowEstimate: 62000,
      highEstimate: 62000, 
      comments: 'technically this is more bearish and most probable thing',
      chartLink: 'https://www.tradingview.com/x/asdagfsg/',
      videoLink: 'https://www.youtube.com/watch?v=hWMueTIcOfc',
      likes: 49,
      dislikes: 6,
      status: 'Disactive',
      datCreatedFormat: 'Thursday, November 1, 2021 17:43',
    },
    {
      id: 5,
      contributor: 'Best Crypto Man',
      coin: 'LINK-USD',
      dateCreated: '10/30/21 11:41 PM',
      _1h: 'Bearish',
      _4h: 'Bearish - Strong',
      _1d: 'Bearish',
      _1w: 'Bullish - Strong',
      _1m: 'Bearish',
      lowEstimate: 62000,
      highEstimate: 62000, 
      comments: 'BTCUSDT has broken its structure to the downside, now',
      chartLink: 'https://www.tradingview.com/x/sdfdsgdsgsd/',
      videoLink: 'https://www.youtube.com/watch?v=QfZruW3NI6c',
      likes: 45,
      dislikes: 10,
      status: 'Active',
      datCreatedFormat: 'Thursday, October 27, 2021 17:43',
    },
  ];*/

  return (
    <Grid container spacing={2} columns={12} direction="row" justifyContent="space-between" alignItems="center" style={{width:'100%', margin: 0}} >
        <DashboardTopMenu _apiLogOff={props._apiLogOff} userDetails={props.userDetails} />

        <Grid item xs={12} sm={12} md={12} lg={12} align="center" justify="center" style={{overflowX: 'auto'}}>
          {rows.length} Results
          {/*<GridView columns={columns} rows={rows} />*/}
          {
          loading ? 
          <LinearProgress />
          :
          <ConsumerHistoryGrid2 columns={columns} rows={rows} minWidth={1400} />
          }
          <div style={{cursor:'pointer', color:'#cc0000'}} onClick={() => _api_get_analyse_all_admin()}>Load More</div>
        </Grid>

    </Grid>
  );
}
import React, { useState, useEffect, useContext } from 'react';
import { ThemeContext, ConstantsContext } from "../index";
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import { useHistory } from "react-router-dom";
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Popper from '@mui/material/Popper';
import Avatar from '@mui/material/Avatar';
import Stack from '@mui/material/Stack';
import PersonIcon from '@mui/icons-material/Person';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import ClickAwayListener from '@mui/core/ClickAwayListener';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import MenuIcon from '@mui/icons-material/Menu';
import Logo from '../assets/img/logo100_100.png';
import DarkModeIcon from '@mui/icons-material/DarkMode';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import LightModeIcon from '@mui/icons-material/LightMode';
//import CurrencyBitcoinIcon from '@mui/icons-material/CurrencyBitcoin';
import PaidIcon from '@mui/icons-material/Paid';
import ButtonCustomStyle from "./ButtonCustomStyle";
import {isMobile} from 'react-device-detect';
import EthereumGold from '../assets/img/ethereum-gold.png';



const Item = styled(Typography)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'left',
  fontSize: 13,
  color: theme.palette.text.secondary,
}));

let adminRoute = '';
let adminRouteTitle = '';

export default function DashboardTopMenu(props) {

  const [theme, toggleTheme, activeTheme, dartCurrentHistoricalDate, setDartCurrentHistoricalDateFnc] = useContext(ThemeContext);
  const [staticText] = useContext(ConstantsContext);
  //console.log("theme DashboardTopMenu", theme);

  const history = useHistory();

  //const [adminRoute, setAdminRoute] = useState('');
  //const [adminRouteTitle, setAdminRouteTitle] = useState('');

  const changeHistoryFnc = (_from, _to, _toTitle) => {
    
    changeHistory(_from, '/' + _to)
    //setAdminRoute(_to);
    //setAdminRouteTitle(_toTitle);
    adminRoute = _to;
    adminRouteTitle = _toTitle;
  };

  const changeHistory = (_from, _to) => {
    history.push(_to, { from: _from })
  };

  const styles = {
    position: 'absolute',
    top: 32,
    right: 0,
    left: -80,
    zIndex: 1,
    p: 1,
    bgcolor: theme.infoPanelBackground,
    color: theme.foreground,
    width:80,

  };

  const admin_styles = {
    position: 'absolute',
    top: 32,
    
    left: 10,
    zIndex: 1,
    p: 1,
    bgcolor: 'background.paper',
    width:150,

  };

  //////////////////////////////////////Admin handle stuff popup menu

  const [adminMenuOpen, setAdminMenuOpen] = React.useState(false);

  const adminMenuHandleClick = () => {
    setAdminMenuOpen((prev) => !prev);
    //console.log('dvvdvd')
  };

  const adminMenuHandleClickAway = () => {
    setAdminMenuOpen(false);
  };

  //////////////////////////////////////Avatar handle stuff popup menu

  const [open, setOpen] = React.useState(false);

  const handleClick = () => {
    setOpen((prev) => !prev);
  };

  const handleClickAway = () => {
    setOpen(false);
  };

  const logOff = () => {
    //props.setUserLogedIn(false);
    //changeHistory('/', '/');
    //console.log('log off')
    props._apiLogOff();
  }

  const EthereumGoldIcon = () => {
    return(
      <img src={EthereumGold} style={{width:20, height:20}} />
    )
  }

  const gotoBackFnc = () => {

    console.log('backBtn', props.backBtn);
    changeHistory('/', '/DashboardCoin/' + props.backBtn);
  }

  return (
    <>
      {props.userDetails == null ? 
      <Grid item xs={12} sm={12} md={12} lg={12} align="left" justify="left">
        <div style={{width:'100%'}}>
          <div style={{display:'flex'}}>
            <div style={{cursor:'pointer', paddingLeft:5, display: 'flex', justifyContent: 'center', alignItems: 'center'}} onClick={() => changeHistory('/', '/')}><span><img src={Logo} style={{width:30, height:30}} /></span><span style={{paddingLeft:5}}>Home</span></div>
          </div>
        </div>
      </Grid> : 
      !props.userDetails.user_loged_in ? 
      <Grid item xs={12} sm={12} md={12} lg={12} align="left" justify="left">
        <div style={{width:'100%'}}>
          <div style={{display:'flex'}}>
            <div style={{cursor:'pointer', paddingLeft:5, display: 'flex', justifyContent: 'center', alignItems: 'center'}} onClick={() => changeHistory('/', '/')}><span><img src={Logo} style={{width:30, height:30}} /></span><span style={{paddingLeft:5}}>Home</span></div>
          </div>
        </div>
      </Grid>
      : 
      <>
        <Grid item xs={6} sm={6} md={6} lg={6} align="left" justify="left">
          <div style={{width:'100%'}}>
            <div style={{display:'flex'}}>
              {
              props.selectedDart == null ? 
                <>
                  {
                    props.userDetails.is_admin == 1 ? 
                      <ClickAwayListener onClickAway={adminMenuHandleClickAway}>
                        <Box sx={{ position: 'relative' }}>
                          <div style={{fontSize:20}}>
                            
                            <span style={{paddingLeft:5}}>
                              <Button
                                  onClick={adminMenuHandleClick}
                                  style={{
                                  cursor:'pointer',
                                  textTransform: 'none',
                                  width:20,
                                  }}
                              >
                                <MenuIcon />
                                Menu
                              </Button>
                            </span>
                            <span>
                              <Button
                                  style={{
                                    cursor:'default',
                                    textTransform: 'none',
                                    width:20,
                                  }}
                              >
                                <ArrowForwardIcon style={{width:24, height:24, color:'#7F7F7F'}} />
                              </Button>
                            </span>
                          </div>
                          {adminMenuOpen ? (
                            <Box sx={admin_styles}>
                              <div style={{paddingTop:10, cursor:'pointer', fontWeight:'bold', fontSize:14, color:'#6076D2'}} onClick={() => changeHistoryFnc('/', '')}>
                                Dashboard
                              </div>
                              <div style={{paddingTop:20, cursor:'pointer', fontWeight:'bold', fontSize:14, color:'#6076D2'}} onClick={() => changeHistoryFnc('/', 'AdminGridView', 'Grid View')}>
                                Grid View
                              </div>
                              <div style={{paddingTop:20, cursor:'pointer', fontWeight:'bold', fontSize:14, color:'#6076D2'}} onClick={() => changeHistoryFnc('/', 'AdminContributorActivity', 'Contributor Activity')}>
                                Contributor Activity
                              </div>
                              <div style={{paddingTop:20, cursor:'pointer', fontWeight:'bold', fontSize:14, color:'#6076D2'}} onClick={() => changeHistoryFnc('/', 'AdminUserGrowth', 'User Growth')}>
                                User Growth
                              </div>
                              <div style={{paddingTop:20, cursor:'pointer', fontWeight:'bold', fontSize:14, color:'#6076D2'}} onClick={() => changeHistoryFnc('/', 'AdminSitesVisits', 'Sites Visits')}>
                                Site Visits
                              </div>
                              <div style={{paddingTop:20, cursor:'pointer', fontWeight:'bold', fontSize:14, color:'#6076D2'}} onClick={() => changeHistoryFnc('/', 'AdminViewsPerCoin', 'Views Per Coin')}>
                                Views per Coin	
                              </div>
                              <div style={{paddingTop:20, cursor:'pointer', fontWeight:'bold', fontSize:14, color:'#6076D2'}} onClick={() => changeHistoryFnc('/', 'AdminInviteAFriendStats', 'Invite A Friend Stats')}>
                                Invite a Friend Stats
                              </div>
                              <div style={{paddingTop:20, cursor:'pointer', fontWeight:'bold', fontSize:14, color:'#6076D2'}} onClick={() => changeHistoryFnc('/', 'AdminCreateCoinPair', 'Create Coin-pair')}>
                                Create Coin-pair
                              </div>
                            </Box>
                          ) : null}
                        </Box>
                      </ClickAwayListener>
                    : null
                  }
                  {
                    props.userDetails.is_admin == 1 ? 
                      <div style={{fontSize:20}}>
                        {adminRoute == '' ? 
                          <ButtonCustomStyle
                              changeHistory={true}
                              doFnc={null}
                              changeHistoryFrom='/'
                              changeHistoryTo='/Dashboard'
                              btnName='Dashboard'
                          />
                        :
                          <div style={{fontSize:14, paddingTop:8, paddingLeft:5}}>
                            {adminRouteTitle}
                          </div>
                        }
                      </div>
                    : 
                      <>
                        <div style={{fontSize:20}}>
                          <div style={{color:'#0076B8', cursor:'pointer', paddingLeft:5, display: 'flex', justifyContent: 'center', alignItems: 'center'}} onClick={() => changeHistory('/', '/Dashboard')}><span><img src={Logo} style={{width:30, height:30}} /></span><span style={{paddingLeft:5}}>
                              <ButtonCustomStyle
                                  changeHistory={true}
                                  doFnc={null}
                                  changeHistoryFrom='/'
                                  changeHistoryTo='/Dashboard'
                                  btnName='Dashboard'
                              />
                            </span></div>
                          
                        </div>
                        {props.backBtn != null && props.backBtn != '' ? <><div style={{fontSize:15, paddingTop:7}}>/</div><div style={{fontSize:15, paddingTop:7, paddingLeft:7, color:theme.foreground, cursor:'pointer'}} onClick={() => gotoBackFnc()}>Back</div></> : null}
                      </>
                  }
                  {
                    props.isDashboard && props.isDashboard == 'yes' 
                    ?
                    <>
                      <div style={{paddingLeft:10}}>
                        <Avatar sx={{ width: 30, height: 30 }}
                          onClick={() => changeHistory('/', '/BuildPortfolio')}
                          style={{
                          cursor:'pointer',
                          }}
                        >
                          <MoreHorizIcon />
                        </Avatar>
                      </div>
                      <div style={{paddingLeft:1}}>
                        <ButtonCustomStyle
                            changeHistory={true}
                            doFnc={null}
                            changeHistoryFrom='/'
                            changeHistoryTo='/BuildPortfolio'
                            btnName='Edit Portfolio Information'
                        />
                      </div>
                    </>
                    :
                    null
                  }
                  
                </>
                :
                <>
                  <div style={{fontSize:20, cursor:'pointer'}}>
                  <div style={{color:'#0076B8', cursor:'pointer', paddingLeft:5, display: 'flex', justifyContent: 'center', alignItems: 'center'}} onClick={() => changeHistory('/', '/Dashboard')}><span><img src={Logo} style={{width:30, height:30}} /></span><span style={{paddingLeft:5}}>
                              <ButtonCustomStyle
                                  changeHistory={true}
                                  doFnc={null}
                                  changeHistoryFrom='/'
                                  changeHistoryTo='/Dashboard'
                                  btnName='Dashboard'
                              />
                            </span></div>
                  </div>
                  
                  <div style={{paddingLeft:10, paddingTop:6}}>
                      <ArrowForwardIcon style={{width:24, height:24, color:'#7F7F7F'}} />
                  </div>
                  <div style={{paddingLeft:10, paddingTop:6, color:'#7F7F7F'}}>
                      {props.selectedDart}
                  </div>
                </>
              }
              
            </div>
          </div>
        </Grid>
        <Grid item xs={6} sm={6} md={6} lg={6} align="right" justify="right">
          <div style={{width:'100%'}}>
              <div style={{display:'flex', width:props.userDetails.usertype == 1 ? 120 : 90}}>
                <span>
                  {activeTheme == 'light' ? 
                    <DarkModeIcon style={{width:30, height:30, cursor:'pointer', color:'#093A58'}} onClick={() => toggleTheme()} />
                  :
                    <LightModeIcon style={{width:30, height:30, cursor:'pointer', color:'#FFFFFF'}} onClick={() => toggleTheme()} />
                  }
                </span>
                {props.userDetails.usertype == 1 ?
                  <span style={{paddingLeft:10}}>
                    <AccountBalanceWalletIcon style={{width:30, height:30, cursor:'pointer'}} onClick={() => changeHistory('/', '/Portfolio')} />
                  </span>
                :
                  null
                }
                
                
                {/*<span style={{paddingLeft:10}}>
                  <ButtonCustomStyle
                      changeHistory={true}
                      doFnc={null}
                      changeHistoryFrom='/'
                      changeHistoryTo='/EarnMoney'
                      btnName='Earn Money'
                      endIcon={<EthereumGoldIcon />}
                  />
                </span>*/}
                <span style={{paddingLeft:10}}>
                  <ClickAwayListener onClickAway={handleClickAway}>
                    <Box sx={{ position: 'relative' }}>
                      <Avatar sx={{ width: 30, height: 30 }}
                        onClick={handleClick}
                        style={{
                        cursor:'pointer',
                        }}
                      >
                        <PersonIcon />
                      </Avatar>
                      {open ? (
                        <Box sx={styles}>
                          <div style={{paddingTop:10, cursor:'pointer', fontWeight:'bold', fontSize:14, color: theme.foreground, textAlign:'left'}} onClick={() => changeHistory('/', '/MyInfo')}>
                            My Info
                          </div>
                          {/*<div style={{paddingTop:20, cursor:'pointer', fontWeight:'bold', fontSize:14, color: theme.foreground, textAlign:'left'}}>
                            {isMobile ? 
                              typeof window.ethereum !== 'undefined' && window.ethereum.isMetaMask ? 
                                <div onClick={() => changeHistory('/', '/MySubscriptions')}>
                                Payments
                                </div>
                              :
                                <a href={`https://metamask.app.link/dapp/${staticText.path}/MySubscriptions`} style={{fontWeight:'bold', fontSize:14, color: theme.foreground, textAlign:'left'}}>
                                  Payments
                                </a>
                            :
                              <div onClick={() => changeHistory('/', '/MySubscriptions')}>
                              Payments
                              </div>
                            }                            
                          </div>*/}
                          <div style={{paddingTop:20, cursor:'pointer', fontWeight:'bold', fontSize:14, color: theme.foreground, textAlign:'left'}} onClick={() => logOff()}>
                            Log Off
                          </div>
                        </Box>
                      ) : null}
                    </Box>
                  </ClickAwayListener>

                </span>
              </div>
              
          </div>
        </Grid>
      </>}
    </>
  );
}
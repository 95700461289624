import React, { useState, useEffect, useRef, useContext } from 'react';
import { ThemeContext, ConstantsContext } from "../index";
import SignUp from "./SignUp";
import DartsDesign from "./DartsDesign";
import DartsDesign2 from "./DartsDesign2";


import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Button from '@mui/material/Button';


import '../App.css';
import { createStyles, makeStyles, useTheme, Theme } from '@material-ui/core/styles';
import FormReferAFriend from "./FormReferAFriend";
import SelectLabels from "./SelectLabels";
import DashboardTopMenu from "./DashboardTopMenu";


import Box from '@mui/material/Box';
import Popper from '@mui/material/Popper';
import { useHistory } from "react-router-dom";

import NativeSelect from '@mui/material/NativeSelect';
import DartsDesign2Historic from "./DartsDesign2Historic";

import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import TextField from '@mui/material/TextField';

import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';

import dayjs from 'dayjs';
import Stack from '@mui/material/Stack';
import PnlListView from "./PnlListView";

import moment from 'moment'


let local_currentHistoricData = 'current';
const useStyles = makeStyles((theme3: Theme) =>
  createStyles({
    root: {
      display: 'flex',
    },
    copyright: {
      padding: theme3.spacing(2,2),
      alignItems: 'center',
      flex: 1,
      justifyContent: 'center'
    },
    nested: {
      paddingLeft: theme3.spacing(1),
    },

    outerDiv: {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
      width: '100%',
      justifyContent: 'space-between'

    },
    leftDiv: {
      display: 'flex',
      flexDirection: 'column',
      flexBasis: '100%',
      flex: 1
    },
    rightDiv: {
      display: 'flex',
      flexDirection: 'column',
      flexBasis: '100%',
      flex: 1
    }
  }),
);

let myPortfolioDoneAndUndone = [];


export default function RouteDashboardConsumer(props) {

  const [theme, toggleTheme, activeTheme, dartCurrentHistoricalDate, setDartCurrentHistoricalDateFnc, currentDartDate, setCurrentDartDateFnc] = useContext(ThemeContext);
  const [staticText] = useContext(ConstantsContext);

  let intervalId = 0; 

  const classes = useStyles();
  const theme3 = useTheme();

  

  

  const [randomKeyUpdate, setRandomKeyUpdate] = useState(0);
  const [myPortfolioListArr, setMyPortfolioListArr] = useState([]);

  const [coin_pair_ids, setCoin_pair_ids] = useState('');

  

  const [currentHistoricData, setCurrentHistoricData] = useState(dartCurrentHistoricalDate);

  const [dateValue, setDateValue] = React.useState(currentDartDate);

  const handleDateChange = (newValue) => {
    setDateValue(dayjs(newValue).format('YYYY-MM-DD'));
    setCurrentDartDateFnc(dayjs(newValue).format('YYYY-MM-DD'))
    console.log('newValue = ', newValue, 'handleDateChange newValue = ', dayjs(newValue).format('YYYY-MM-DD'))
    
  };

  //const [myPortfolioDoneAndUndone, setMyPortfolioDoneAndUndone] = useState([]);

  ////////////////////////////////////////_api_get_portfolio
  function _api_get_portfolio_callback(_data) {

    if(_data.status_code != 2) {
      
      //console.log('get_portfolio error = ' + _data.msg);
      //console.log('get_portfolio code = ' + _data.status_code);
      if(_data.status_code == 4) {
        changeHistory('/Dashboard', '/BuildPortfolio')
      }
      
    } else {
      
      //console.log('get_portfolio email was successfully sent');

      //_data.data.sort((a, b) => a.left_side > b.left_side ? 1 : -1);


        ////////////////////////////////////////////
        ////////////////////////////////////////////
        let coin_pair_idsArr = [];
        let coin_pair_idsStr = '';

        _data.data.sort((a, b) => a.coin_pair_name > b.coin_pair_name ? 1 : -1);
        myPortfolioDoneAndUndone = _data.data;

        _data.data.map((value, key) => {

            //coin_pair_ids = coin_pair_ids + 
            coin_pair_idsArr.push(value.id);
        })
        coin_pair_idsStr = coin_pair_idsArr.toString();
        setCoin_pair_ids(coin_pair_idsStr)
        //console.log('coin_pair_idsStr = ' + coin_pair_idsStr)

        console.log('interval local_currentHistoricData = ', dartCurrentHistoricalDate)
        if(props.userDetails.usertype == 0) {
            _api_get_analyse_average_for_coin_ids(coin_pair_idsStr, dartCurrentHistoricalDate);
        } else {
            _api_get_analyse_for_coin_ids(coin_pair_idsStr);
            //_api_get_analyse(props.coinPairId)
        }

      //console.log('svsvsvsv = ' + _data.data.length)
      if(_data.data.length == 0) {
        changeHistory('/Dashboard', '/BuildPortfolio')
      }
      ///////////////////////////////////////////////////////////////////////
    }
    
  }

  const _api_get_portfolio = () => {

    setRandomKeyUpdate(Math.random())
    let data = [
      {'key':'action', 'value': 'get_portfolio'},
      {'key':'loggined_token', 'value': props.userDetails.loggined_token}
    ]

    props._apiConnect(staticText.path_darts, data, _api_get_portfolio_callback)
  }
  //////////////////////////////////////

  ////////////////////////////////////////_api_get_analyse_average_for_coin_ids
  function _api_get_analyse_average_for_coin_ids_callback(_data) {

    if(_data.status_code != 2) {
      
      //console.log('get_analyse_average_for_coin_ids error = ' + _data.msg);
      //console.log('get_analyse_average_for_coin_ids code = ' + _data.status_code); 
      if(_data.status_code == 101) {
        changeHistory('/Dashboard', '/MySubscriptions')
      }     
    } else {
      
      //console.log('get_analyse_average_for_coin_ids email was successfully sent');

      //_data.data.sort((a, b) => a.coin_pair_name > b.coin_pair_name ? 1 : -1);
      setMyPortfolioListArr(_data.data)

      //console.log('get_analyse_average_for_coin_ids = ' + _data.data)

      ///////////////////////////////////////////////////////////////////////
    }
  }

  function _api_get_historical_analyse_average_for_coin_ids_callback(_data) {

    if(_data.status_code != 2) {
      
      //console.log('get_analyse_average_for_coin_ids error = ' + _data.msg);
      //console.log('get_analyse_average_for_coin_ids code = ' + _data.status_code); 
      if(_data.status_code == 101) {
        changeHistory('/Dashboard', '/MySubscriptions')
      }     
    } else {
      
      console.log('_api_get_historical_analyse_average_for_coin_ids_callback')
      //console.log('get_analyse_average_for_coin_ids email was successfully sent');

      //_data.data.sort((a, b) => a.coin_pair_name > b.coin_pair_name ? 1 : -1);

      setMyPortfolioListArr(_data.data)
      console.log(JSON.stringify(_data.data))

      //console.log('get_analyse_average_for_coin_ids = ' + _data.data)


      ///////////////////////////////////////////////////////////////////////
    }
  }

  /////////////////////////////////////////////////////////////////////////////

  

  const _api_get_analyse_average_for_coin_ids = (_coin_pair_ids, _currentHistoricData) => {

    setRandomKeyUpdate(Math.random())

    let data = [];

    if(_currentHistoricData == 'current') {

      data = [
        {'key':'action', 'value': 'get_analyse_average_for_coin_ids'},
        {'key':'loggined_token', 'value': props.userDetails.loggined_token},
        {'key':'coin_pair_ids', 'value': _coin_pair_ids}
      ]

      props._apiConnect(staticText.path_darts, data, _api_get_analyse_average_for_coin_ids_callback)
    } 
    else if(_currentHistoricData == 'current_date') {

      data = [
        {'key':'action', 'value': 'get_analyse_average_for_coin_ids'},
        {'key':'loggined_token', 'value': props.userDetails.loggined_token},
        {'key':'coin_pair_ids', 'value': _coin_pair_ids},
        {'key':'as_of_day', 'value': currentDartDate}
      ]

      props._apiConnect(staticText.path_darts, data, _api_get_analyse_average_for_coin_ids_callback)
    }
    else {

      data = [
        {'key':'action', 'value': 'get_historical_analyse_average_for_coin_ids'},
        {'key':'loggined_token', 'value': props.userDetails.loggined_token},
        {'key':'coin_pair_ids', 'value': _coin_pair_ids}
      ]

      props._apiConnect(staticText.path_darts, data, _api_get_historical_analyse_average_for_coin_ids_callback)
    }
  }

  /////////////////////////////////////////////////////////////////////////////

  ////////////////////////////////////////_api_get_analyse_for_coin_ids
  function _api_get_analyse_for_coin_ids_callback(_data) {

    //if(_data.status_code != 2) {
      
      if(_data.status_code == 101) {
        changeHistory('/Dashboard', '/MySubscriptions')
      } else {

        let coin_pair_idsArr = [];
        //console.log('length = ' + myPortfolioDoneAndUndone.length);
  
        let isAdded = false;
        if(myPortfolioDoneAndUndone != undefined) { 
          myPortfolioDoneAndUndone.map((value, key) => {
  
            isAdded = false;
            //console.log(value.id);
            if(_data.data != undefined) {
              _data.data.map((_value, _key) => {
  
                if(value.id == _value.coin_pair_id) {
  
                    isAdded = true;
                    coin_pair_idsArr.push(_value);
                }
              })
            }
            if(!isAdded) {
  
                coin_pair_idsArr.push({'coin_pair_id':value.id, 'coin_pair_name':value.left_side + '-' + value.right_side, '_1h':'-100', '_4h':'-100', '_1d':'-100', '_1w':'-100', '_1m':'-100'});
            }
          })
        }
  
        //coin_pair_idsArr.sort((a, b) => a.coin_pair_name > b.coin_pair_name ? 1 : -1);
        
        setMyPortfolioListArr(coin_pair_idsArr)
        
  
        //console.log('get_analyse_for_coin_ids = ' + _data.data)
  
  
        ///////////////////////////////////////////////////////////////////////
      }

      
    //}
    
  }

  const _api_get_analyse_for_coin_ids = (_coin_pair_ids) => {

    //console.log('_coin_pair_ids = ' + _coin_pair_ids)
    setRandomKeyUpdate(Math.random())
    let data = [
      {'key':'action', 'value': 'get_analyse_for_coin_ids'},
      {'key':'loggined_token', 'value': props.userDetails.loggined_token},
      {'key':'coin_pair_ids', 'value': _coin_pair_ids}
    ]

    props._apiConnect(props._path, data, _api_get_analyse_for_coin_ids_callback)
  }
  //////////////////////////////////////

  const chooseCurrentHistoryFncSelect = (e) => {

    console.log('chooseCurrentHistoryFncSelect = ', e.target.value)

    setCurrentHistoricData(e.target.value);
    local_currentHistoricData = e.target.value;
    setDartCurrentHistoricalDateFnc(e.target.value)
    
    //if(e.target.value == 'current') {


      _api_get_analyse_average_for_coin_ids(coin_pair_ids, e.target.value)
    //} else {

      //_api_get_historical_analyse_average_for_coin_ids(coinPairId, e.target.value)
    //}
    
    //chooseCoinPairFnc(e.target.value)
  }
  

  const history = useHistory();

  const changeHistory = (_from, _to) => {
    history.push(_to, { from: _from })
  };

  useEffect(() => {

    if(!props.userDetails.user_loged_in) {
  
      changeHistory('/Dashboard', '/')
     
    } else {

      if(props._path != undefined) {

        _api_get_portfolio()

        intervalId = setInterval(() => {
          _api_get_portfolio()
        }, 300000); //300000

        return () => clearInterval(intervalId);


      } else {
        
      }
    }
  }, []);

  useEffect(() => {

  }, [dartCurrentHistoricalDate]);

  return (
    <Grid container spacing={2} columns={12} direction="row" justifyContent="space-between" alignItems="flex-start" style={{width:'100%', margin: 0}} >
      
      <DashboardTopMenu isDashboard="yes" _apiLogOff={props._apiLogOff} userDetails={props.userDetails} />
      <>
        <Grid item xs={12} sm={12} md={12} lg={4} align="center" justify="center">
          <Grid container spacing={2} columns={12} direction="row" justifyContent="space-between" alignItems="flex-start" style={{width:'100%', margin: 0}} >
            <Grid item xs={12} sm={12} md={12} lg={12} align="center" justify="center">
              <Grid container spacing={2} columns={12} align="center" justify="center" style={{height: '100%', width:'100%', maxWidth:400}} direction="row" justifyContent="center" alignItems="center" >
                <Grid style={{zIndex:1}} item xs={12} sm={12} md={12} lg={12} align="center" justify="center">
                  <div>
                    <PnlListView userDetails={props.userDetails} _apiConnect={props._apiConnect} />
                  </div>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={8} align="center" justify="center">
          <Grid container spacing={2} columns={12} direction="row" justifyContent="space-between" alignItems="flex-start" style={{width:'100%', margin: 0}} >
            <Grid item xs={12} sm={12} md={12} lg={12} align="center" justify="center">
              <Grid container spacing={2} columns={12} direction="row" justifyContent="space-between" alignItems="center" style={{width:'100%', maxWidth:900, paddingBottom:10, backgroundColor:theme.infoPanelBackground, borderRadius: 10}} >
                <Grid item xs={12} sm={12} md={6} lg={6} align="center" justify="center">
                  <div style={{fontSize:20}}>
                    
                    {props.userDetails.usertype == 0 ? 
                      'Averaged Trend Darts'
                    :
                      props.userDetails.username + '\'s Trend Darts'
                    }
                  </div>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6} align="center" justify="center">
                  <div style={{display: 'flex', alignItems: 'center', width:360, height:20}}>
                  
                    <SelectLabels color="#00B050" name="Bullish - Strong" />
                    <SelectLabels color="#25FF88" name="Bullish" />
                    <SelectLabels color="#808080" name="Neutral" />
                    <SelectLabels color="#FFA6A0" name="Bearish" />
                    <SelectLabels color="#FF0000" name="Bearish - Strong" />
                  </div>
                </Grid>
              </Grid>
            </Grid>
            {props.userDetails.usertype == 0 ? 
              <Grid item xs={12} sm={12} md={12} lg={12} align="center" justify="center">
                  <div style={{alignItems: 'center', maxWidth:800, backgroundColor:theme.infoPanelBackground, paddingTop:20}}>
                      <NativeSelect
                        defaultValue={dartCurrentHistoricalDate}
                        inputProps={{
                          name: 'current_history_select_name',
                          id: 'current_history_select',
                        }}
                        style={{width:'100%', paddingLeft:10, backgroundColor:'#FFFFFF'}}
                        onChange={chooseCurrentHistoryFncSelect}
                      >
                        <option key="current" value="current">Current</option>
                        <option key="current_historic_data" value="current_historic_data">Current + Historic Data</option>
                        <option key="current_date" value="current_date">As of Date</option>
                      </NativeSelect>
                  </div>
                  {dartCurrentHistoricalDate == 'current_date' ? 
                    <div style={{alignItems: 'center', maxWidth:800, backgroundColor:'#FFFFFF', paddingTop:10}}>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <Stack spacing={3}>
                            <DesktopDatePicker
                              minDate={dayjs(new Date()).subtract(12, 'month')}
                              maxDate={dayjs(new Date()).subtract(3, 'day')}
                              label="Date"
                              inputFormat="YYYY-MM-DD"
                              value={dateValue}
                              onChange={handleDateChange}
                              renderInput={(params) => <TextField {...params} />}
                            />
                          </Stack>
                        </LocalizationProvider>
                    </div>
                  :
                    null
                  }
              </Grid>
            :
              null
            
            }
            
            <Grid item xs={12} sm={12} md={12} lg={12} align="center" justify="center">
              <Grid container spacing={2} rowSpacing={2} columns={12} direction="row" justifyContent="space-between" alignItems="center" style={{width:'100%', maxWidth:props.userDetails.usertype == 0 ? 850 : 1200}} >
                
              
              {
                myPortfolioListArr.length > 0 ?
                  currentHistoricData == 'current' || currentHistoricData == 'current_date' ? 
                    myPortfolioListArr.map((value, key) => {

                      return (

                        <Grid key={key} item xs={12} sm={6} md={4} lg={4} align="center" justify="center">
                          <div onClick={() => changeHistory('/', (props.userDetails.usertype == 0 ? '/DashboardCoin/' + value["coin_pair_id"] + '/' + (value["coin_pair_name"]) : '/DashboardCoinEdit/' + value["coin_pair_id"]))}>

                              <DartsDesign2 
                                  key={value["coin_pair_id"] + '_' + randomKeyUpdate} 
                                  coinPairId={value["coin_pair_id"]} 
                                  name={(value["coin_pair_name"])} 
                                  userDetails={props.userDetails}
                                  _values={value}
                              />
                          </div>
                        </Grid>
                        
                      );
                    })
                  :
                    myPortfolioListArr != undefined ?
                        myPortfolioListArr.map((value, key) => (


                          value[0] != undefined ? 

                              <Grid key={key} item xs={12} sm={6} md={4} lg={4} align="center" justify="center" style={{width:300}}>
                                <div onClick={() => changeHistory('/', (props.userDetails.usertype == 0 ? '/DashboardCoin/' + value[0]["coin_pair_id"] + '/' + (value[0]["coin_pair_name"]) : '/DashboardCoinEdit/' + value[0]["coin_pair_id"]))}>

                                    <DartsDesign2Historic 
                                        key={value["coin_pair_id"] + '_' + randomKeyUpdate} 
                                        coinPairId={value["coin_pair_id"]} 
                                        name={(value["coin_pair_name"])} 
                                        userDetails={props.userDetails}
                                        _values={value}
                                    />
                                </div>
                              </Grid>

                          :
                            null
                        ))
                    :
                        null
                :
                  null
              }
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} align="center" justify="center">
            <div style={{height:30}}></div>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} align="center" justify="center">
            <div style={{fontSize:10, padding:20, textAlign:'left'}}>
                DISCLAIMER: The material presented on CryptoMarketDarts.com platform has no regard to any specific investment objectives, financial situation or needs of 
                any of its users. This information is presented solely for informational purposes and is not to be construed as a recommendation, solicitation or 
                an offer to buy or sell (long or short) any asset classes covered by CryptoMarketDarts.com platform. None of the content on this platform should 
                be taken as investment advice. CryptoMarketDarts.com or its subsidiary or holding entities or any of the contributing analysts or users should not 
                be seen, treated or otherwise assumed to be investment or financial advisors. The views and opinion expressed in the platform are speculative 
                and do not guarantee any specific result or profit. Trading and investing is extremely high risk and can result in the loss of all of your capital. Any 
                opinions expressed in this platform are subject to change without notice. CryptoMarketDarts.com is not under any obligation to update or keep 
                current the information contained herein. CryptoMarketDarts.com or its subsidiary or holding entities may have an interest in the securities, 
                commodities, cryptocurrencies and or derivatives of any entities referred to in this platform. CryptoMarketDarts.com or its subsidiary or holding 
                entities accept no liability whatsoever for any loss or damage of any kind arising out of the use of all or any part of materials presented on 
                CryptoMarketDarts.com. CryptoMarketDarts.com recommends that you consult with a licensed and qualified professional before making any 
                investment or trading decisions.
            </div>
        </Grid>
      </>
    </Grid>
  );
}
import React, { useState, useEffect, useContext } from 'react';
import { ThemeContext, ConstantsContext } from "../index";


import {numberWithCommas, roundDecimals, numberToAmericanFormat, roundNumberIfNeeded} from './functions';

import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';



import '../App.css';
import SelectLabels from "./SelectLabels";


import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import ThumbDownAltIcon from '@mui/icons-material/ThumbDownAlt';

import ThumbDownOffAltIcon from '@mui/icons-material/ThumbDownOffAlt';
import ThumbUpOffAltIcon from '@mui/icons-material/ThumbUpOffAlt';

import { useHistory } from "react-router-dom";



export default function RouteDashboardUser(props) {

  const [staticText] = useContext(ConstantsContext);
  const [theme, toggleTheme, activeTheme, dartCurrentHistoricalDate, setDartCurrentHistoricalDateFnc, currentDartDate, setCurrentDartDateFnc] = useContext(ThemeContext);

  const colorsArr = {"-100": "#FFFFFF", "2": "#00B050", "1": "#25FF88", "0": "#808080", "-1": "#FFA6A0", "-2": "#FF0000"};
  const colorsNameArr = {"-100": "#FFFFFF", "2": "Bullish - Strong", "1": "Bullish", "0": "Neutral", "-1": "Bearish", "-2": "Bearish - Strong"};

  const [username, setUsername] = useState(props.username);
  const [telegram, setTelegram] = useState(props.telegram);
  const [youtube, setYoutube] = useState(props.youtube);
  const [twitter, setTwitter] = useState(props.twitter);

  const [likeCount, setLikeCount] = useState(props.likeCount);
  const [dislikeCount, setDislikeCount] = useState(props.dislikeCount);
  const [myDecisionCount, setMyDecisionCount] = useState(0);

  

  useEffect(() => {

    if(!props.userDetails.user_loged_in) {
  
      changeHistory('/DashboardCoin', '/')
    } else {
      //changeHistory('/ReferAFriend', '/SignUp')

      //_api_get_other_user(props.user_id);
      //_api_get_like_dislike(props.id);
      
      
      
    }
  }, []);

  const likeFnc = () => {
    //
    _api_set_like_dislike(props.id, 1);
  }

  const dislikeFnc = () => {
    //
    _api_set_like_dislike(props.id, 2);
  }

  ////////////////////////////////////////_api_get_portfolio
  function _api_get_other_user_callback(_data) {

    if(_data.status_code != 2) {
      
      //console.log('get_other_user error = ' + _data.msg);
      //console.log('get_other_user code = ' + _data.status_code);
      
    } else {
      
      //console.log('get_other_user was successfully sent');

      //_data.data.sort((a, b) => a.left_side > b.left_side ? 1 : -1);

      //setMyPortfolioListArr(_data.data)

      ///////////////////////////////////////////////////////////////////////
      setUsername(_data.data.username);
      setTelegram(_data.data.telegram);
      setTwitter(_data.data.twitter);
      setYoutube(_data.data.youtube);
      //setUsername(_data.data.username);


      //console.log(_data.data.username)
      //console.log(_data.data.telegram)
      //console.log(_data.data.twitter)
      //console.log(_data.data.youtube)
      //console.log(_data.data.creation_date)
    }
    
  }

  const _api_get_other_user = (user_id) => {

    let data = [
      {'key':'action', 'value': 'get_other_user'},
      {'key':'loggined_token', 'value': props.userDetails.loggined_token},
      {'key':'user_id', 'value': user_id}
    ]

    props._apiConnect(staticText.path_user, data, _api_get_other_user_callback)
  }
  //////////////////////////////////////

  ////////////////////////////////////////_api_get_portfolio
  function _api_get_like_dislike_callback(_data) {

    if(_data.status_code != 2) {
      
      //console.log('_api_get_like_dislike error = ' + _data.msg);
      //console.log('_api_get_like_dislike code = ' + _data.status_code);
      
    } else {
      
      //console.log('_api_get_like_dislike was successfully sent');

      //_data.data.sort((a, b) => a.left_side > b.left_side ? 1 : -1);

      //setMyPortfolioListArr(_data.data)

      ///////////////////////////////////////////////////////////////////////
      //setUsername(_data.data.username);
      //setTelegram(_data.data.telegram);
      //setTwitter(_data.data.twitter);
      //setYoutube(_data.data.youtube);
      //setUsername(_data.data.username);

      setLikeCount(_data.data.likes_count);
      setDislikeCount(_data.data.dislikes_count);
      setMyDecisionCount(_data.data.type);

      //console.log(_data.data.likes_count)
      //console.log(_data.data.dislikes_count)
      //console.log('user id:' + _data.data.user_id + ':' + _data.data.type)
      //console.log(_data.data.creation_date)
    }
    
  }

  const _api_get_like_dislike = (id) => {

    let data = [
      {'key':'action', 'value': 'get_like'},
      {'key':'loggined_token', 'value': props.userDetails.loggined_token},
      {'key':'object_type', 'value': 0},
      {'key':'object_id', 'value': id}
    ]

    props._apiConnect(staticText.path_like, data, _api_get_like_dislike_callback)
  }
  //////////////////////////////////////

  ////////////////////////////////////////_api_set_portfolio
  function _api_set_like_dislike_callback(_data) {

    if(_data.status_code != 2) {
      
      //console.log('_api_set_like_dislike error = ' + _data.msg);
      //console.log('_api_set_like_dislike code = ' + _data.status_code);
      
    } else {
      
      //console.log('_api_set_like_dislike was successfully sent');

      _api_get_like_dislike(props.id);
      //_data.data.sort((a, b) => a.left_side > b.left_side ? 1 : -1);

      //setMyPortfolioListArr(_data.data)

      ///////////////////////////////////////////////////////////////////////
      //setUsername(_data.data.username);
      //setTelegram(_data.data.telegram);
      //setTwitter(_data.data.twitter);
      //setYoutube(_data.data.youtube);
      //setUsername(_data.data.username);

      //setLikeCount(_data.data.likes_count);
      //setDislikeCount(_data.data.dislikes_count);
      //setMyDecisionCount(_data.data.type);

      //console.log(_data.data.likes_count)
      //console.log(_data.data.dislikes_count)
      //console.log('user id:' + _data.data.user_id + ':' + _data.data.type)
      //console.log(_data.data.creation_date)
    }
    
  }

  const _api_set_like_dislike = (id, type) => {

    let data = [
      {'key':'action', 'value': 'set_like'},
      {'key':'loggined_token', 'value': props.userDetails.loggined_token},
      {'key':'object_type', 'value': 0},
      {'key':'object_id', 'value': id},
      {'key':'type', 'value': type}
    ]

    props._apiConnect(props._path, data, _api_set_like_dislike_callback)
  }
  //////////////////////////////////////

  const chooseAnalytic = (user_id) => {

    //console.log('chooseAnalytic user_id = ' + user_id)
    changeHistory('/DashboardCoin/' + props.coinPairId, '/ConsumerHistory/' + user_id + '/' + props.coinPairId + '/' + props.username + '/' + props.cryptopairname)
  }

  const history = useHistory();

  const changeHistory = (_from, _to) => {
    history.push(_to, { from: _from })
  };

  const linkMakeOpenable = (url) => {

    if(url != undefined) {

      if (!url.match(/^https?:\/\//i)) {
        url = 'https://' + url;
      }
      return url;
    }
    else {

      return "";
    }
  }
  function getCurrentDate() {
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, '0'); // Add 1 and pad with leading zero if necessary
  
    return `${year}-${month}`;
  }
  

  const doPnlClick = (_data_id) => {

    changeHistory('/', '/PortfolioUser/' + getCurrentDate() + '/' + _data_id)
    console.log('doPnlClick: ', _data_id, 'getCurrentDate = ', getCurrentDate())
  }

  return (

          <Grid container spacing={2} columns={12} direction="row" justifyContent="flex-start" alignItems="left" style={{width:'100%', borderRadius: 10, padding:5, border: '1px solid #F2F2F2', fontSize:16}} >
            <Grid item xs={12} sm={12} md={12} lg={12} align="left" justify="left">
              <div style={{display: 'flex', alignItems: 'center'}}>
                <div style={{fontSize:16, fontWeight: 'bold'}}>
                  Review: {props.reviewNum} of {props.totalCount} 
                  (
                    Contributor: {props.username}{props.pnl == '' ? null : <span>; <span style={{cursor:'pointer', textDecoration:'underline'}} onClick={() => doPnlClick(props.user_id)}>Portfolio MTD P&L {roundNumberIfNeeded(props.pnl, 2)}%</span></span>} {props.telegram != '' && props.telegram != undefined ? (<a href={linkMakeOpenable(props.telegram)} target="_blank" rel="noreferrer" style={{color:theme.foreground, textDecoration: 'underline'}}>Telegram</a>) : null} {props.twitter != '' && props.twitter != undefined ? <a href={linkMakeOpenable(props.twitter)} target="_blank" rel="noreferrer" style={{color:theme.foreground, textDecoration: 'underline'}}>Twitter</a> : null} {props.youtube != ''&& props.youtube != undefined ? <a href={linkMakeOpenable(props.youtube)} target="_blank" rel="noreferrer" style={{color:theme.foreground, textDecoration: 'underline'}}>Youtube</a> : null}
                  )
                </div>
                {dartCurrentHistoricalDate != 'current_historic_data' || props.isForTodayIndex == 0 ?
                  dartCurrentHistoricalDate != 'current_date' ? 
                    <div style={{paddingLeft:10, color:'#959595'}}>updated {props.updatedTime}</div> 
                  : 
                    null
                :
                  null
                }
              </div>
              
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} align="left" justify="left">
              <div style={{display: 'flex', alignItems: 'center', height:'100%'}}>
                <div style={{paddingRight:5}}>Trend:</div>
                <SelectLabels color={colorsArr[props._1h]} name={'1H: ' + colorsNameArr[props._1h]} />
                <SelectLabels color={colorsArr[props._4h]} name={'4H: ' + colorsNameArr[props._4h]} />
                <SelectLabels color={colorsArr[props._1d]} name={'1D: ' + colorsNameArr[props._1d]} />
                <SelectLabels color={colorsArr[props._1w]} name={'1W: ' + colorsNameArr[props._1w]} />
                <SelectLabels color={colorsArr[props._1m]} name={'1M: ' + colorsNameArr[props._1m]} />
              </div>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} align="left" justify="left">
              <div style={{display: 'flex', alignItems: 'center'}}>
              <div style={{paddingRight:5}}>High Est: <span style={{fontWeight: 'bold'}}>${numberToAmericanFormat(props.highEst, 5)}</span>; Low Est: <span style={{fontWeight: 'bold'}}>${numberToAmericanFormat(props.lowEst, 5)}</span></div>
              </div>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} align="left" justify="left">
              <div style={{display: 'flex', alignItems: 'center'}}>
                  {props.comment}
              </div>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} align="left" justify="left">
              <Grid container spacing={2} columns={12} direction="row" justifyContent="space-between" alignItems="flex-start" style={{width:'100%', maxWidth:800, paddingBottom:10, fontSize:16}} >
                <Grid item xs={12} sm={12} md={6} lg={6} align="left" justify="left" order={{ xs: 2, sm: 2, md: 1, lg: 1 }}>
                    <div>Price target chart: {props.chartLink == '' ? 'no chart' : null}</div>
                    
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6} align="left" justify="left" order={{ xs: 1, sm: 1, md: 2, lg: 2 }}>
                    <div>
                      Video: {props.videoLink == '' ? 'no video' : <a href={linkMakeOpenable(props.videoLink)} target="_blank" rel="noreferrer" style={{color:theme.foreground}}>{props.videoLink}</a>}
                    </div>
                </Grid>
              </Grid>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} align="left" justify="left">
            <div style={{width:'100%', height:'auto'}}>
              {
              props.chartLink == '' ? null : 
              <a href={linkMakeOpenable(props.chartLink)} target="_blank" rel="noreferrer"><img src={props.chartLink} style={{width:'100%', height:'auto'}} alt="chart" /></a>
              }
            </div>
          </Grid>

          <Grid container item xs={12} sm={5} md={5} justifyContent="flex-start" style={{paddingRight:20}} order={{ xs: 2, sm: 1, md: 1, lg: 1 }}>
            <div style={{textAlign:'left', maxWidth:300}}>
              <div style={{cursor:'pointer', textDecoration:'underline', color:theme.foreground}} onClick={() => chooseAnalytic(props.user_id)}>View {props.username}’s history of updates</div>
            </div>
          </Grid>
          <Grid container item xs={12} sm={7} md={7} justifyContent="flex-end" style={{paddingRight:20}} order={{ xs: 1, sm: 2, md: 2, lg: 2 }}>
              
              
              <div style={{display: 'flex', textAlign:'right', maxWidth:300}}>
                <div style={{textAlign:'right'}}>
                    <Button
                    onClick={() => likeFnc()}
                    style={{
                        cursor:'pointer',
                        textTransform: 'none',
                        color:'#FF1F1F',
                        fontSize:20
                    }}
                    >
                      {myDecisionCount == 1 ? <ThumbUpIcon style={{color:theme.foreground}} /> : <ThumbUpOffAltIcon style={{color:theme.foreground}} />}
                    </Button>
                </div>
                <div style={{paddingLeft:1, paddingTop:5, textAlign:'right'}}>({likeCount})</div>
                <div style={{paddingLeft:40, textAlign:'right'}}>
                    <Button
                    onClick={() => dislikeFnc()}
                    style={{
                        cursor:'pointer',
                        textTransform: 'none',
                        color:'#FF1F1F',
                        fontSize:20
                    }}
                    >
                      {myDecisionCount == 2 ? <ThumbDownAltIcon style={{color:theme.foreground}} /> : <ThumbDownOffAltIcon style={{color:theme.foreground}} />}
                    </Button>
                </div>
                <div style={{paddingLeft:1, paddingTop:5, textAlign:'right'}}>({dislikeCount})</div>
              </div>
          </Grid>
        </Grid>

        
    
  );
}


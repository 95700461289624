import React, { useState, useEffect, useContext } from 'react';
import { ThemeContext, ConstantsContext } from "../index";
import axios from "axios";
import SignUp from "./SignUp";
import DartsDesign from "./DartsDesign";

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import Checkbox from '@mui/material/Checkbox';
import Button from '@mui/material/Button';
import Alert from '@mui/material/Alert';

import '../App.css';
import { createStyles, makeStyles, useTheme, Theme } from '@material-ui/core/styles';
import FormReferAFriend from "./FormReferAFriend";
import SelectLabels from "./SelectLabels";
import DashboardTopMenu from "./DashboardTopMenu";

import ButtonCustomStyle from "./ButtonCustomStyle";


import Box from '@mui/material/Box';
import Popper from '@mui/material/Popper';
import { useHistory } from "react-router-dom";




const useStyles = makeStyles((theme3: Theme) =>
  createStyles({
    root: {
      display: 'flex',
    },
    copyright: {
      padding: theme3.spacing(2,2),
      alignItems: 'center',
      flex: 1,
      justifyContent: 'center'
    },
    nested: {
      paddingLeft: theme3.spacing(1),
    },

    outerDiv: {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
      width: '100%',
      justifyContent: 'space-between'

    },
    leftDiv: {
      display: 'flex',
      flexDirection: 'column',
      flexBasis: '100%',
      flex: 1
    },
    rightDiv: {
      display: 'flex',
      flexDirection: 'column',
      flexBasis: '100%',
      flex: 1
    }
  }),
);


export default function RouteBuildPortfolio(props) {


  const [theme] = useContext(ThemeContext);
  const [staticText] = useContext(ConstantsContext);

  const classes = useStyles();
  const theme3 = useTheme();

  const [portfolioListArr, setPortfolioListArr] = useState([])
  const [myPortfolioListArr, setMyPortfolioListArr] = useState([])
  const [myPortfolioListArrLength, setMyPortfolioListArrLength] = useState(myPortfolioListArr.length)
  const [buildYourPortfolioPressed, setBuildYourPortfolioPressed] = useState(false);
  const [referAFriendPressed, setReferAFriendPressed] = useState(false);
  const [myInfoPressed, setMyInfoPressed] = useState(false);

  const [successMessage, setSuccessMessage] = useState('');

  
  const [portfolioWalletBalances, setPortfolioWalletBalances] = useState([])
  const [activePositionsCoinsNames, setActivePositionsCoinsNames] = useState([])
  

  useEffect(() => {

    if(!props.userDetails.user_loged_in) {
  
      changeHistory('/BuildPortfolio', '/')
     
    } else {
      //changeHistory('/ReferAFriend', '/SignUp')
      //console.log('_path = ', props._path)
      //console.log('loggined_token = ', props.userDetails.loggined_token)
      if(props._path != undefined) {

        if(props.userDetails.usertype == 0) {

          _api_user_get_coin_pair()
        } else {

          _api_contributor_get_coin_pair()
        }
        
        
      } else {
        
      }
    }
  }, []);

  ////////////////////////////////////////_api_contributor_get_coin_pair
  function _api_contributor_get_coin_pair_callback(_data) {

    if(_data.status_code != 2) {
      
      //console.log('contributor_get_coin_pair error = ' + _data.msg);
      //console.log('contributor_get_coin_pair code = ' + _data.status_code);
      
    } else {
      
      //console.log('_api_contributor_get_coin_pair email was successfully sent');
      //console.log('_data = ' + _data.data[0]);
      _data.data.sort((a, b) => a.left_side > b.left_side ? 1 : -1);
      setPortfolioListArr(_data.data)

      _api_get_portfolio()

    }
    
  }

  const _api_contributor_get_coin_pair = () => {

    let data = [
      {'key':'action', 'value': 'contributor_get_coin_pair'},
      {'key':'loggined_token', 'value': props.userDetails.loggined_token}
    ]

    props._apiConnect(staticText.path_darts, data, _api_contributor_get_coin_pair_callback)
  }
  //////////////////////////////////////

  ////////////////////////////////////////_api_user_get_coin_pair
  function _api_user_get_coin_pair_callback(_data) {

    if(_data.status_code != 2) {
      
      //console.log('_api_user_get_coin_pair error = ' + _data.msg);
      //console.log('_api_user_get_coin_pair code = ' + _data.status_code);
      
    } else {
      
      //console.log('_api_user_get_coin_pair was successfully sent');
      //console.log('_data = ' + _data.data);
      _data.data.sort((a, b) => a.left_side > b.left_side ? 1 : -1);
      setPortfolioListArr(_data.data)

      _api_get_portfolio()

    }
    
  }

  const _api_user_get_coin_pair = () => {

    let data = [
      {'key':'action', 'value': 'user_get_coin_pair'},
      {'key':'loggined_token', 'value': props.userDetails.loggined_token}
    ]

    props._apiConnect(staticText.path_darts, data, _api_user_get_coin_pair_callback)
  }
  //////////////////////////////////////

  ////////////////////////////////////////_api_get_portfolio
  function _api_get_portfolio_callback(_data) {

    if(_data.status_code != 2) {
      
      //console.log('get_portfolio error = ' + _data.msg);
      //console.log('get_portfolio code = ' + _data.status_code);
      
    } else {
      
      //console.log('get_portfolio email was successfully sent');
      //console.log('_data = ' + _data.data[0]);
      

      let coin_pair_idsArr_int = [];

      if(props.userDetails.usertype == 0) {

        _data.data.map((value, key) => {

          coin_pair_idsArr_int.push(parseInt(value["id"]));
        })        
      } else {

        
        _data.data.map((value, key) => {

          coin_pair_idsArr_int.push(parseInt(value["id"]));
        })

        //////////////////check trading portfolio
        _api_get_active_positions();
      }
      

      setMyPortfolioListArr(coin_pair_idsArr_int)
      setMyPortfolioListArrLength(coin_pair_idsArr_int.length)
      ///////////////////////////////////////////////////////////////////////
    }
    
  }

  const _api_get_portfolio = () => {

    let data = [
      {'key':'action', 'value': 'get_portfolio'},
      {'key':'loggined_token', 'value': props.userDetails.loggined_token}
    ]

    props._apiConnect(staticText.path_darts, data, _api_get_portfolio_callback)
  }
  //////////////////////////////////////

  ////////////////////////////////////////_api_get_active_positions
  function _api_get_active_positions_callback(_data) {
    console.log('_api_get_active_positions_callback _data', _data)
    if(_data.status_code != 2) {
      
    } else {

      let _portfolioWalletBalances = [];
      let _activePositionsCoinsNames = '';

      _data.data.sort((a, b) => a.left_side > b.left_side ? 1 : -1);


      
      _data.data.map((value, key) => {

        _portfolioWalletBalances.push({coin_pair_id: parseInt(value["coin_pair_id"]), coin_pair_name: value["coin_pair_name"]})
        if(key > 0) {
          _activePositionsCoinsNames = _activePositionsCoinsNames + ', ';
        }
        _activePositionsCoinsNames = _activePositionsCoinsNames + value["coin_pair_name"];
       
      })

      

      console.log('_portfolioWalletBalances = ', _portfolioWalletBalances)
      setPortfolioWalletBalances(_portfolioWalletBalances)
      setActivePositionsCoinsNames(_activePositionsCoinsNames);

    }    
  }

  const _api_get_active_positions = () => {

    
    let data = [
      {'key':'action', 'value': 'get_active_positions'},
      {'key':'loggined_token', 'value': props.userDetails.loggined_token}
    ]

    //strInputString = strInputString.replace(/'/g, "\\'");

    props._apiConnect(staticText.path_darts, data, _api_get_active_positions_callback)
  }
  //////////////////////////////////////////////

  ////////////////////////////////////////_api_set_portfolio
  function _api_set_portfolio_callback(_data) {

    if(_data.status_code != 2) {
      
      //console.log('_api_set_portfolio error = ' + _data.msg);
      //console.log('_api_set_portfolio code = ' + _data.status_code);
      
    } else {
      
      setSuccessMessage('Changes has been successfully saved');
      _api_get_portfolio();

      history.goBack();
    }
    
  }

  const _api_set_portfolio = () => {

    let coin_pair_ids = myPortfolioListArr.toString();

    let data = [
      {'key':'action', 'value': 'set_portfolio'},
      {'key':'coin_pair_ids', 'value': coin_pair_ids},
      {'key':'loggined_token', 'value': props.userDetails.loggined_token}
    ]

    props._apiConnect(staticText.path_darts, data, _api_set_portfolio_callback)
  }
  //////////////////////////////////////

  const portfolioChange = (event) => {
    let val = parseInt(event.target.value);
    let arr = myPortfolioListArr;
    //console.log('value = ', val);
    //console.log('|' + event.target.checked);
    if(event.target.checked) {
      const index = arr.indexOf(val);
      if (index == -1) {
        arr.push(val);
      }
      
    } else {
      const index = arr.indexOf(parseInt(event.target.value));
      if (index > -1) {
        arr.splice(index, 1);
      }
    }

    setMyPortfolioListArr(arr);
    setMyPortfolioListArrLength(myPortfolioListArr.length);
    //console.log(arr);
  };


  const setBuildYourPortfolioPressedFnc = () => {
    //do some portfolio saving stuff
    //changeHistory('/', props.userDetails.usertype == 0 ? '/DashboardUser' : '/DashboardConsumer')
    //console.log(myPortfolioListArr.length);
    _api_set_portfolio()
  };



  const history = useHistory();

  const changeHistory = (_from, _to) => {
    history.push(_to, { from: _from })
  };

  

  return (
    
    <Grid container spacing={2} columns={12} direction="row" justifyContent="space-between" alignItems="center" style={{width:'100%', margin: 0}} >
        <DashboardTopMenu isDashboard="yes" _apiLogOff={props._apiLogOff} userDetails={props.userDetails} />
        <Grid item xs={12} sm={12} md={12} lg={12} align="center" justify="center">
          <div style={{fontSize:20, paddingTop:10, padding:5, backgroundColor:theme.infoPanelBackground, borderRadius: 10, maxWidth:800, width:'100%'}}>
            {
            props.userDetails.usertype == 0 ? 
              'Averaged Trend Darts'
            :
              props.userDetails.username + '\'s Trend Darts'
            }
          </div>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} align="center" justify="center">
          <div style={{fontSize:16, padding:5, width:250, backgroundColor:theme.infoPanelBackground, borderRadius: 10 }}>
            Build Your Portfolio
          </div>
          <div style={{width:250, textAlign:'left', paddingTop:10}}>
            <div style={{width:250, textAlign:'left', padding:5, paddingLeft:10, paddingRight:10, border: '1px solid #0070C0', borderRadius: 5, fontSize:14}}>Choice ({myPortfolioListArrLength}/{portfolioListArr.length})</div>
            <div 
              style={{
                height: 400,
                width: '100%',
                marginTop: theme3.spacing.unit * 3,
                overflow: 'auto',
                border: '1px solid #0070C0', borderRadius: 5,
                padding:5,
                paddingLeft:10, paddingRight:10
              }}
            >
              <FormControl component="fieldset" variant="standard">
                <FormGroup>
                {
                  
                  portfolioListArr.map((value, key) => {

                    let checkboxDisabled = false
                    portfolioWalletBalances.map((_value, _key) => {

                      if(value.id == _value.coin_pair_id) {
                        checkboxDisabled = true;
                      }
                      
                    })

                    let valBool= myPortfolioListArr.indexOf(parseInt(value.id)) > -1 ? true : false

                    return (
                      <div key={key}>
                      <FormControlLabel 
                        
                        control={<Checkbox disabled={checkboxDisabled} value={parseInt(value.id)} checked={valBool} onChange={portfolioChange} style={{color:theme.foreground}} />} 
                        label={
                          <Typography style={{fontSize:13, color:checkboxDisabled ? theme.btn_disabled : theme.btn_color}}>{value.left_side}-{value.right_side}</Typography>
                        }
                      />
                      </div>
                    );
                  })
                }
                </FormGroup>
              </FormControl>
            </div>

          </div>
          <div style={{width:230, textAlign:'right'}}>
            <ButtonCustomStyle
                                    changeHistory={null}
                                    doFnc={setBuildYourPortfolioPressedFnc}
                                    changeHistoryFrom=''
                                    changeHistoryTo=''
                                    btnName='Save'                          
                                />
          </div>
          {
          props.userDetails.usertype == 1 && portfolioWalletBalances.length > 0 ? 
            <div style={{width:300}}>
              <Alert severity="warning">{`There ${portfolioWalletBalances.length > 1 ? 'are' : 'is'} either active position${portfolioWalletBalances.length > 1 ? 's' : ''} or open orders for ${activePositionsCoinsNames} in your current month virtual portfolio. Cannot remove ${activePositionsCoinsNames} at this time.`}</Alert>
              
            </div>
          :
            null
          }
          
          {successMessage != '' ? 
          <div style={{width:230, textAlign:'center'}}>
            <Alert severity="success">{successMessage}</Alert>
          </div>
          : null}
          
        </Grid>
    </Grid>
      
  );
}
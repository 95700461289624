import React, { useEffect, useState, useContext}  from 'react';
import { ThemeContext, ConstantsContext } from "../index";
import { NumericFormat } from 'react-number-format';
import {numberToAmericanFormat, roundDecimals} from './functions';

export default function CoingeckoCurrentCoinPriceWarning(props) {

  const [theme] = useContext(ThemeContext);

  let priceWarning = '';

    if(props.action == 'sell_limit') {

      priceWarning = 'max value: '
    }
    else if(props.action == 'sell_stop_limit') {

      priceWarning = 'max value: '
    }

  return (
    <>
        {props.currentQuantity > 0 ?
            <div style={{fontSize:10}}>
                <span>{priceWarning}</span>
                <span>
                <NumericFormat
                    className={`input_${theme.btn_color == '#C7E7FA' ? 'dark' : 'light'}`}
                    displayType="text"
                    value={props.currentQuantity} 
                    thousandSeparator="," 
                    allowNegative={false} 
                    decimalScale={8}
                    renderText={(value) => <>{value}</>}
                />
                </span>
            </div>
        :
            <div style={{fontSize:10}}>insufficient balance</div>
        }
    </>
  );
}
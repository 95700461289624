import * as React from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { makeStyles } from '@mui/styles';
import { createTheme } from '@mui/material/styles';
import Divider from '@mui/material/Divider';
import {
  randomCreatedDate,
  randomTraderName,
  randomUpdatedDate,
} from '@mui/x-data-grid-generator';
const useStyles = makeStyles({
    root: {
      '& .super-app-theme--cell': {
        backgroundColor: 'rgba(224, 183, 60, 0.55)',
        color: '#1a3e72',
        fontWeight: '600',
      },
      '& .super-app._m2v': {
        backgroundColor: '#FF0000',
        color: '#1a3e72',
        fontWeight: '600',
      },
      '& .super-app._m1v': {
        backgroundColor: '#FFA6A0',
        color: '#1a3e72',
        fontWeight: '600',
      },
      '& .super-app._0v': {
        backgroundColor: '#808080',
        color: '#1a3e72',
        fontWeight: '600',
      },
      '& .super-app._p1v': {
        backgroundColor: '#25FF88',
        color: '#1a3e72',
        fontWeight: '600',
      },
      '& .super-app._p2v': {
        backgroundColor: '#00B050',
        color: '#1a3e72',
        fontWeight: '600',
      },
      '& .super-app._c10v': {
        backgroundColor: '#cc0000',
        color: '#1a3e72',
        fontWeight: '600',
        fontSize:10,
      },
      '& .super-app._c20v': {
        backgroundColor: '#00cccc',
        color: '#1a3e72',
        fontWeight: '600',
        fontSize:10,

      },
    },
  });

  

export default function ConsumerHistoryGrid2(props) {
    const classes = useStyles();

  return (
    <div style={{ height: 600, width: '100%', minWidth:props.minWidth, overflowX: 'auto'}}>
      <div style={{display: 'flex', alignItems: 'center', padding:10}}>
        {
            props.columns.map((value, key) => {
                return (
                  <div key={`ConsumerHistoryGrid2_${key}`} style={{fontSize:12, fontWeight: 'bold', width:value.width}}>
                      {value.headerName}
                  </div>
                )
            })
        }
      </div>
      
      <div style={{alignItems: 'center', height: 500, overflowY: 'auto', padding:10}}>
        {
            props.rows.map((rows_value, rows_key) => {
                return (
                    <div key={`ConsumerHistoryGrid2_${rows_key}`}>
                      <div style={{paddingTop:5}}></div>
                      <Divider />
                      <div style={{paddingTop:5}}></div>
                      <div style={{display: 'flex', alignItems: 'center'}}>
                      {
                          props.columns.map((value, key) => {
                              return (
                              <div key={`ConsumerHistoryGrid2_${rows_key}_${key}`} style={{fontSize:14, width:value.width}}>
                                  {rows_value[value.field]}
                              </div>
                              )
                          })
                      }
                      </div>
                    </div>
                )
            })
        }
      </div>
      
    </div>
  );
}
import React, { useState, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import { useHistory } from "react-router-dom";
import Button from '@mui/material/Button';
import ButtonCustomStyle from "./ButtonCustomStyle";
import SocialLinks from "./SocialLinks";


export default function BottomPart(props) {

  const history = useHistory();

  const changeHistory = (_from, _to) => {
    history.push(_to, { from: _from })
  };

  return (
    <>
        <div style={{position:'absolute', bottom:0, width:'100%'}}>
            <Grid container spacing={1} columns={12} rowSpacing={1} direction="row" justifyContent="center" alignItems="center" style={{width:'100%', fontSize:16}}>

                <Grid item xs={12} sm={12} md={12} lg={12} align="center" justify="center">
                    <SocialLinks />
                </Grid>
                
                <Grid item xs={12} sm={12} md={6} lg={6} align="center" justify="center">
                        <span>
                            <ButtonCustomStyle
                                changeHistory={true}
                                doFnc={null}
                                changeHistoryFrom='/'
                                changeHistoryTo='/About'
                                btnName='About Us'                          
                            />
                        </span>
                        <span>
                            <ButtonCustomStyle
                                changeHistory={true}
                                doFnc={null}
                                changeHistoryFrom='/'
                                changeHistoryTo='/Career'
                                btnName='Career'                          
                            />
                        </span>
                        <span>
                            <ButtonCustomStyle
                                changeHistory={true}
                                doFnc={null}
                                changeHistoryFrom='/'
                                changeHistoryTo='/Terms'
                                btnName='Terms of Service'
                            />
                        </span>
                        <span>
                            <ButtonCustomStyle
                                changeHistory={true}
                                doFnc={null}
                                changeHistoryFrom='/'
                                changeHistoryTo='/Privacy'
                                btnName='Privacy'
                            />
                        </span>
                        <span>
                            <ButtonCustomStyle
                                changeHistory={true}
                                doFnc={null}
                                changeHistoryFrom='/'
                                changeHistoryTo='/SiteTutorial'
                                btnName='Site Tutorial'
                            />
                        </span>
                        <span>
                            <ButtonCustomStyle
                                changeHistory={true}
                                doFnc={null}
                                changeHistoryFrom='/'
                                changeHistoryTo='/Contact'
                                btnName='Feedback / Contact Us'
                            />
                        </span>
                        {
                        props.user_loged_in == null ? 
                            <>
                            {/*<span>
                                <ButtonCustomStyle
                                    changeHistory={true}
                                    doFnc={null}
                                    changeHistoryFrom='/'
                                    changeHistoryTo='/EarnMoney'
                                    btnName='Earn Money'
                                />
                            </span>*/}
                            </>
                        :
                        props.user_loged_in ? 
                            <span>
                                <ButtonCustomStyle
                                    changeHistory={true}
                                    doFnc={null}
                                    changeHistoryFrom='/'
                                    changeHistoryTo='/ReferAFriend'
                                    btnName='Recommend Us'
                                />
                            </span>
                        :
                            <>
                            {/*<span>
                                <ButtonCustomStyle
                                    changeHistory={true}
                                    doFnc={null}
                                    changeHistoryFrom='/'
                                    changeHistoryTo='/EarnMoney'
                                    btnName='Earn Money'
                                />
                            </span>*/}
                            </>
                        }
                        
                    
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6} align="center" justify="center">
                    <span style={{paddingLeft:50, fontStyle: 'italic', fontSize:10}}>
                        © 2022 CryptoMarketDarts. All rights reserved. Patent pending.
                        </span>
                </Grid>
            </Grid>
        </div>
    </>
  );
}
import React, { useState, useEffect, useRef, useContext } from 'react';
import { ThemeContext, ConstantsContext } from "../index";
import Grid from '@mui/material/Grid';
import SignUp from "./SignUp";
import { useHistory, useParams } from "react-router-dom";
import Button from '@mui/material/Button';
//import homepage_image from '../assets/img/homepage_image.png';
import homepage_image from '../assets/img/signinlogo.png';
import YoutubeEmbed from "./YoutubeEmbed";
import {isMobile} from 'react-device-detect';
import ButtonCustomStyle from "./ButtonCustomStyle";
const Web3 = require("web3");

export default function RouteSignUp(props) {

  const [theme] = useContext(ThemeContext);
  const [staticText] = useContext(ConstantsContext);

  let { _promo } = useParams();
  let _metamask_url = "https://metamask.app.link/dapp/" + staticText.path + "/SignUp";

  if(_promo == undefined) {
    _promo = '';
  } else {
    localStorage.setItem('_promo', _promo);
  }

  const [isMobileAndNoMetamask, setIsMobileAndNoMetamask] = useState(false)
  const [metamaskLink, setMetamaskLink] = useState('')
  useEffect(() => {

    if(isMobile) {

      if(typeof window.ethereum !== 'undefined' && window.ethereum.isMetaMask) {


      } else {

        setIsMobileAndNoMetamask(true)

        //_metamask_url = metamaskLink + localStorage.getItem('_promo') != '' && localStorage.getItem('_promo') != null ? "/" + localStorage.getItem('_promo') : ""
        //_metamask_url2 = metamaskLink2 + localStorage.getItem('_promo') != '' && localStorage.getItem('_promo') != null ? "/" + localStorage.getItem('_promo') : ""
        if(localStorage.getItem('_promo') != '' && localStorage.getItem('_promo') != null) {

          setMetamaskLink(_metamask_url + "/" + localStorage.getItem('_promo'));
        } else {

          setMetamaskLink(_metamask_url);
        }
      }
      //
    }
  }, []);

  const history = useHistory();

  const changeHistory = (_from, _to) => {
    history.push(_to, { from: _from })
  };

  return (
    props.bbb != 'withpromo' ? <div style={{display: 'flex',  justifyContent:'center', alignItems:'center', textAlign: 'center', margin:'auto'}}>
      <Grid container spacing={2} columns={12} style={{height: '100%', paddingTop:20}} direction="column" justifyContent="center" alignItems="center" >
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Grid container spacing={4} columns={12} direction="row" justifyContent="flex-start" alignItems="flex-start" style={{padding:20}} >
            <Grid container direction="column" item xs={12} sm={12} md={6} lg={6} justifyContent="flex-start" alignItems="center">
              <div style={{paddingTop:10, fontSize:28, fontStyle: 'italic', whiteSpace: 'nowrap'}}>
                You are seconds away from
              </div>
              <div style={{fontSize:28, fontStyle: 'italic'}}>
                something big...
              </div>
              <div style={{paddingTop:20}}>
                <div style={{textAlign:'center', width:300}} className="videoResponsive">
                  <YoutubeEmbed embedId="5SdzR5qUwkM" />
                </div>
              </div>
            </Grid>
            <Grid container direction="column" item xs={12} sm={12} md={6} lg={6} justifyContent="flex-start" alignItems="center">
              <div style={{paddingTop:20, margin:'auto'}}>
                
                <SignUp metamaskLink={metamaskLink} isMobileAndNoMetamask={isMobileAndNoMetamask} userDetails={props.userDetails} _apiSignUp={props._apiSignUp} signUpErrorMessage={props.signUpErrorMessage} userEmail={props.userEmail} userProfileType={props.userProfileType} _apiConnect={props._apiConnect} _path={props._path} setUserDetails={props.setUserDetails} setLoggined_token={props.setLoggined_token} _api_chech_user_token={props._api_chech_user_token} />
                
              </div>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
    :
    <div>promo = {localStorage.getItem('_promo')} {props.bbb}</div>
  );
}